import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import FetchService from '../FetchService';

// App Components
import Footer from '../components/Footer';

import apiRoutes from '../apiRoutes';

export default function CenterDetail(props) {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(
            <div>
              <a className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block" href="/">
                {t('menu::Dashboard')} /
              </a>
              <a className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block" href="/centers">
                {t('model::lists::centers')} /
              </a>
              <span className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block" href="#">
                {t(props.title)}
              </span>
            </div>
          )}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7 fondoAdmin">
            <CenterDetailContainer {...props} />
            {/* <!-- Footer --> */}
            
          </div>
          <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}
function CenterDetailContainer(props) {
  const { t, i18n } = useTranslation();
  const [record, setRecord] = useState();

  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.CENTER.DETAIL + '/' + props.centerId, setRecord);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header bg-transparent">
              <h1 className="mb-0">
                {t('pages::Center')}: {record ? record.name : ''}
              </h1>
            </div>
            <div className="card-body">
              <table className="table align-items-center table-flush">
                <tbody>
                <tr>
                    <th className="w-15">{t('model::fields::CCN')}</th>
                    <td>{record ? record.CCN : ''}</td>
                  </tr>
                  <tr>
                    <th className="w-15">{t('model::fields::CODCNH')}</th>
                    <td>{record ? record.CODCNH : ''}</td>
                  </tr>
                  <tr>
                    <th className="w-15">{t('model::fields::name')}</th>
                    <td>{record ? record.name : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::typeRoad')}</th>
                    <td>{record ? record.typeRoad : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::address')}</th>
                    <td>{record ? record.address : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::addressNumber')}</th>
                    <td>{record ? record.addressNumber : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::cityCode')}</th>
                    <td>{record ? record.cityCode : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::city')}</th>
                    <td>{record ? record.city : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::provinceCode')}</th>
                    <td>{record ? record.provinceCode : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::provinceName')}</th>
                    <td>{record ? record.provinceName : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::CCAACode')}</th>
                    <td>{record ? record.CCAACode : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::CCAAName')}</th>
                    <td>{record ? record.CCAAName : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::country')}</th>
                    <td>{record ? record.country : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::postalCode')}</th>
                    <td>{record ? record.postalCode : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::phone')}</th>
                    <td>{record ? record.phone : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::email')}</th>
                    <td>{record ? record.email : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::classCode')}</th>
                    <td>{record ? record.classCode : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::classCenter')}</th>
                    <td>{record ? record.classCenter : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::funcionalDependenceCode')}</th>
                    <td>{record ? record.funcionalDependenceCode : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::funcionalDependenceName')}</th>
                    <td>{record ? record.funcionalDependenceName : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::complexCode')}</th>
                    <td>{record ? record.complexCode : ''}</td>
                  </tr>
                  <tr>
                    <th>{t('model::fields::complexName')}</th>
                    <td>{record ? record.complexName : ''}</td>
                  </tr>
                
                  <tr>
                    <th>{t('model::fields::observation')}</th>
                    <td>{record ? record.observation : ''}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
