const selectOptions = {
  PROJECT: {
    TYPE: [
      {
        key: "first",
        value: "first",
        text: "selectOptions::project::types::first",
      },
      {
        key: "tracing",
        value: "tracing",
        text: "selectOptions::project::types::tracing",
      },
      {
        key: "temporary",
        value: "temporary",
        text: "selectOptions::project::types::temporary",
      },
      {
        key: "final",
        value: "final",
        text: "selectOptions::project::types::final",
      },
    ],
    PRIORITY: [
      {
        key: "high",
        value: "high",
        text: "selectOptions::project::priority::high",
      },
      {
        key: "normal",
        value: "normal",
        text: "selectOptions::project::priority::normal",
      },
    ],
    STATE: [
      {
        key: "active",
        value: "active",
        text: "selectOptions::project::state::active",
      },
      {
        key: "paused",
        value: "paused",
        text: "selectOptions::project::state::paused",
      },
      {
        key: "finished",
        value: "finished",
        text: "selectOptions::project::state::finished",
      },
    ],
  },
  ISSUE: {
    STATE: [
      {
        key: "toDo",
        value: "toDo",
        text: "selectOptions::issue::state::toDo",
      },
      {
        key: "inProgress",
        value: "inProgress",
        text: "selectOptions::issue::state::inProgress",
      },
      {
        key: "ready",
        value: "ready",
        text: "selectOptions::issue::state::ready",
      },
    ],
  },
};

export default selectOptions;
