import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// App Components
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import Alert from "react-s-alert";
import $ from "jquery";
import { Modal } from "react-bootstrap";

export default function RequestCenterModal(props) {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState("");
  const [user, setUser] = useState({});
  const [observation, setObservation] = useState("");

  useEffect(() => {
    if(props.idUser){
    FetchService.simpleFetch(
      apiRoutes.USER.DETAIL + "/" + props.idUser,
      setUser
    );}
  }, [props.idUser]);

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleObservation = (event) => {
    setObservation(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    Alert.success("Correo enviado al administrador");
    //TODO configurar servidor de correo para producción
    // let body = {
    //   from: user.email,
    //   to: "pablo.fernandez@fundacionctic.org",
    //   subject: "SOLICITUD CREACIÓN CENTRO",
    //   text:
    //     user.username +
    //     " ha solicitado la creacion del centro: " +
    //     name +
    //     "\n\n" +
    //     observation,
    // };

    // FetchService.simplePost(apiRoutes.MAIL.SENDMAIL, body, (response) => {
    //   if (response.status == 200) {
    //     props.handleCloseRequestCenterModal();
    //     Alert.success("Correo enviado al administrador");
    //   } else {
    //     Alert.error("Error enviando el correo al administrador");
    //   }
    // });

    
  };

  return (
    <React.Fragment>
      <Modal show={props.showRequestCenter} onHide={props.handleCloseRequestCenterModal}>
       
          <form onSubmit={handleSubmit}>
            
          <Modal.Header closeButton>
          <Modal.Title>
                  {t("actions::Request") + " " + t(props.entityName)}
                  </Modal.Title>
                
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-name"
                    >
                      {t("model::fields::name")}
                    </label>
                    <input
                      type="text"
                      id="input-new-name"
                      className="form-control"
                      placeholder={t("model::fields::name")}
                      value={name}
                      onChange={handleName}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-observation"
                    >
                      {t("model::fields::observation")}
                    </label>
                    <input
                      type="text"
                      id="input-new-observation"
                      className="form-control"
                      placeholder={t("model::fields::observation")}
                      value={observation}
                      onChange={handleObservation}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={props.handleCloseRequestCenterModal}
                >
                  {t("actions::Close")}
                </button>
                <button type="submit" className="btn btn-primary">
                  {t("actions::Request")}
                </button>
              </Modal.Footer>
            
          </form>
        
      </Modal>
    </React.Fragment>
  );
}
