import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
// App Components
import RolesGuard from '../RolesGuard';
import Footer from '../components/Footer';
import AssistancesTable from './AssistancesTable';
import NewAssistanceModal from './NewAssistanceModal';


export default function Assistances(props) {
  const { t, i18n } = useTranslation();

  const [filter, setFilter] = useState(false);
  const [idToEdit, setIdToEdit] = useState();
  const [modelUpdatesCounter, setModelUpdatesCounter] = useState(0);
  const [showNew, setShowNew] = useState(false);

  const handleFilter = (event) => {
    setFilter(!filter);
  };

  const handleModelUpdate = () => {
    setModelUpdatesCounter(modelUpdatesCounter + 1);
  };
  const handleOpenNewModal = () => setShowNew(true);
  const handleCloseNewModal = () => setShowNew(false);
  return (
    <div>
      <div className='row'>
        <div className='col'>
          <div className='card shadow'>
            <div className='card-header border-0'>
              <div className='row align-items-center'>
                <div className='col'>
                  <h3 className='mb-0'>{t(props.pageName)}</h3>
                </div>
                <div className='col text-right'>
                  <button type='button' className='btn btn-outline-primary' onClick={handleFilter}>
                    {filter ? <i className='fas fa-toggle-on'></i> : <i className='fas fa-toggle-off'></i>}
                    &nbsp;&nbsp;{t('actions::Filter')}
                  </button>
                  &nbsp;&nbsp;
                  {props.issuePermission?<button
                    type='button'
                    className='btn btn-outline-primary'
                    onClick={handleOpenNewModal}
                  >
                    <i className='fas fa-plus'></i>&nbsp;&nbsp;
                    {t('actions::New')}
                  </button>:""}
                </div>
              </div>
            </div>
            <div className='card-body'>
              <AssistancesTable
                apiRoute={props.apiRoute}
                modelUpdatesCounter={modelUpdatesCounter}
                handleModelUpdate={handleModelUpdate}
                setIdToEdit={setIdToEdit}
                filter={filter}
                issueId={props.issueId}
              />
            </div>
            {props.issuePermission?<NewAssistanceModal
              handleModelUpdate={handleModelUpdate}
              apiRoute={props.apiRoute}
              entityName={props.entityName}
              issue={props.issue}
              issueId={props.issueId}
              modelUpdatesCounter={modelUpdatesCounter}
              handleCloseNewModal={handleCloseNewModal}
              showNew={showNew}
            />:""}
           
          </div>
        </div>
      </div>
    </div>
  );
}
