const avatars = {
  ADMIN: [
    "avatar1.jpg",
    "avatar2.svg",
    "avatar3.svg",
    "avatar4.svg",
    "avatar5.svg",
    "avatar6.svg",
    "avatar7.svg",
    "avatar8.svg",
    "avatar9.svg",
    "avatar10.svg",
    "avatar11.svg",
    "avatar12.svg",
  ],
  INTERNAL: [
    "avatar1.jpg",
    "avatar2.svg",
    "avatar3.svg",
    "avatar4.svg",
    "avatar5.svg",
    "avatar6.svg",
    "avatar7.svg",
    "avatar8.svg",
    "avatar9.svg",
    "avatar10.svg",
    "avatar11.svg",
    "avatar12.svg",
  ],
  EXTERNAL: [
    "avatar13.svg",
    "avatar14.svg",
    "avatar15.svg",
    "avatar16.svg",
    "avatar17.svg",
    "avatar18.svg",
    "avatar19.svg",
    "avatar20.svg",
    "avatar21.svg",
    "avatar22.svg",
    "avatar23.svg",
    "avatar24.svg",
    "avatar25.svg",
    "avatar26.svg",
    "avatar27.svg",
    "avatar28.svg",
    "avatar29.svg",
    "avatar30.svg",
    "avatar31.svg",
    "avatar32.svg",
    "avatar33.svg",
    "avatar34.svg",
    "avatar35.svg",
    "avatar36.svg",
    "avatar37.svg",
    "avatar38.svg",
    "avatar39.svg",
    "avatar40.svg",
    "avatar41.svg",
    "avatar42.svg",
  ],
  ASSISTANT: [
    "avatar13.svg",
    "avatar14.svg",
    "avatar15.svg",
    "avatar16.svg",
    "avatar17.svg",
    "avatar18.svg",
    "avatar19.svg",
    "avatar20.svg",
    "avatar21.svg",
    "avatar22.svg",
    "avatar23.svg",
    "avatar24.svg",
    "avatar25.svg",
    "avatar26.svg",
    "avatar27.svg",
    "avatar28.svg",
    "avatar29.svg",
    "avatar30.svg",
    "avatar31.svg",
    "avatar32.svg",
    "avatar33.svg",
    "avatar34.svg",
    "avatar35.svg",
    "avatar36.svg",
    "avatar37.svg",
    "avatar38.svg",
    "avatar39.svg",
    "avatar40.svg",
    "avatar41.svg",
    "avatar42.svg",
  ],
};

export default avatars;
