import React from 'react';
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
// App Components
import Footer from '../components/Footer';
import apiRoutes from '../apiRoutes';
import webRoutes from '../webRoutes';
import CrudCenter from './CrudCenter';

export default function Centers(props) {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(
            <div>
              <a className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block" href="/">
                {t('menu::Dashboard')} /
              </a>

              <span className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block" href="#">
                {t(props.title)}
              </span>
            </div>
          )}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7 fondoAdmin">
            <CrudCenter
              pageName={'pages::Centers'}
              idUser={props.idUser}
              entityName={'model::entities::center'}
              apiRoute={apiRoutes.CENTER}
              webRoute={webRoutes.CENTER}
            />
            {/* <!-- Footer --> */}
           
          </div>
          <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}
