import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// App Components
import FetchService from '../FetchService';
import AuthService from '../AuthService';
import apiRoutes from '../apiRoutes';
// react-bootstrap-table2
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
// Other
import Alert from 'react-s-alert';

export default function ServiceTable(props) {
  const { t, i18n } = useTranslation();
  const api = props.apiRoute;

  const [records, setRecords] = useState();

  useEffect(() => {
   
    if (!AuthService.isTokenExpired()) {
      FetchService.simpleFetch(api.LIST + (props.projectId ? '/' + props.projectId : ''), setRecords);
    }
  }, [props.modelUpdatesCounter]);

  const handleDelete = async (event) => {
    const id = event.target.getAttribute('data-id');

    if (window.confirm(t('messages::Are you sure do you want to delete it?'))) {
      FetchService.simpleDelete(api.DELETE + '/' + id, () => {
        // Show in app a user friendly message.
        Alert.success(t('messages::Item has been removed'));
        FetchService.simpleFetch(api.LIST + (props.projectId ? '/' + props.projectId : ''), setRecords);
      });
    }
  };

  const handleEdit = (event) => {
    const id = event.target.getAttribute('data-id');
    props.setIdToEdit(id);
  };

  const columns = [
    {
      dataField: 'code',
      text: t('model::fields::code'),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::code'),
          })
        : null,
    },
    {
      dataField: 'name',
      text: t('model::fields::name'),
      sort: true,
      style: {
        whiteSpace: 'normal'
      },
      filter: props.filter
        ? textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::name'),
          })
        : null,
    },
    {
      dataField: 'observation',
      text: t('model::fields::observation'),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::observation'),
          })
        : null,
    },
    {
      dataField: 'id',
      text: t('actions::Actions'),
      sort: false,
      formatter: actionsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '30em' };
      },
    },
  ];

  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp; {from} - {to} &nbsp;<small className="text-muted">[Total: {size}]</small>
    </span>
  );

  const pagination = paginationFactory({
    sizePerPage: 10,
    showTotal: true,
    paginationTotalRenderer: paginationTotalRenderer,
  });

  

  function actionsFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="text-right">
        <React.Fragment>
         
          <button
            type="button"
            data-id={cell}
            className="btn btn-outline-primary btn-sm"
            data-toggle="modal"
            data-target="#edit-modal"
            onClick={handleEdit}
          >
            <i className="fas fa-pen-alt"></i>&nbsp;
            {t('actions::Edit')}
          </button>
          <button type="button" data-id={cell} className="btn btn-outline-danger btn-sm" onClick={handleDelete}>
            <i className="fas fa-times-circle"></i>&nbsp;
            {t('actions::Delete')}
          </button>
        </React.Fragment>
      </div>
    );
  }

  return (
    <React.Fragment>
      {records && records.length ? (
        <div className="table-responsive" style={{ marginBottom: '50px' }}>
          <BootstrapTable
            keyField="id"
            data={records}
            columns={columns}
            caption={props.caption}
            bootstrap4={true}
            headerWrapperClasses="thead-light"
            bordered={false}
            hover
            condensed
            filter={filterFactory()}
            pagination={pagination}
           
          />
        </div>
      ) : (
        <div className="text-center">
          <div className="alert alert-secondary" role="alert">
            {records ? (
              <strong>{t('literals::No data')}</strong>
            ) : (
              <div className="text-center">
                <img src="../spinner.gif" width="100" alt="" style={{ opacity: '0.5' }} />
                <div className="text-muted">
                  <i>{t('literals::Loading')}</i>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
