import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// App Components
import FetchService from '../FetchService';
import apiRoutes from '../apiRoutes';
import Alert from 'react-s-alert';
import $ from "jquery";

export default function NewDocumentModal(props) {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState('');
  const [file, setFile] = useState(null);
  const [observation, setObservation] = useState('');

  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleFile = (event) => {
    
    setFile(event.target.files[0]);
  };
  const handleObservation = (event) => {
    setObservation(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      name: name,
      observation: observation,
      idUser:props.idUser,
      idIssue:props.issueId?props.issueId:null,
      idProject:props.projectId?props.projectId:null,
      idParent: props.idParent?props.idParent:null,
      
      file:file
    };

    FetchService.simplePostFile(props.issueId ? apiRoutes.DOCUMENT.NEW+"/"+props.issueId :apiRoutes.DOCUMENT.NEWRESPONSE, body, () => {
      Alert.success(t('messages::Item has been added'));
      props.handleModelUpdate();
      setName('');
      setObservation('');
      setFile(null);
      $("#file").val('');
    });
  };

  return (
    <React.Fragment>
      <div
        className='modal fade'
        id='new-document-modal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='new-modal-label'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <form onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='new-modal-label'>
                  {props.idParent?t('actions::Add') + ' versión  ' + t(props.entityName):t('actions::Add') + ' ' + t(props.entityName)}
                </h5>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div>
                  <div className='form-group'>
                    <label className='form-control-label' htmlFor='input-new-name'>
                      {t('model::fields::name')}
                    </label>
                    <input
                      type='text'
                      id='input-new-name'
                      className='form-control'
                      placeholder={t('model::fields::name')}
                      value={name}
                      onChange={handleName}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label className='form-control-label' htmlFor='input-new-observation'>
                      {t('model::fields::observation')}
                    </label>
                    <input
                      type='text'
                      id='input-new-observation'
                      className='form-control'
                      placeholder={t('model::fields::observation')}
                      value={observation}
                      onChange={handleObservation}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label className='form-control-label' htmlFor='input-new-observation'>
                      {t('model::fields::document')}
                    </label>
                    
                    <input id="file" type="file"  className='form-control'onChange={handleFile} required/>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' data-dismiss='modal'>
                  {t('actions::Close')}
                </button>
                <button type='submit' className='btn btn-primary'>
                  {t('actions::Submit')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
