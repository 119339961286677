import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

export default function Footer(props) {
  const { t, i18n } = useTranslation();
  return (
    <footer className="footer">
      <div className="row align-items-center justify-content-xl-between">
        <div className="col-xl-6">
          <div className="copyright text-center text-xl-left text-muted ml-4">
            © 2021{' '}
            <a
              href="https://myomics.io/"
              className="font-weight-bold ml-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              myOMICS
            </a>
          </div>
        </div>
        <div className="col-xl-6">
          <ul className="nav nav-footer justify-content-center ">
            <li className="nav-item">
              <a href="https://www.fundacionctic.org/" className="nav-link" target="_blank" rel="noopener noreferrer">
                CTIC - {t('footer::Technological Center')}
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://myomics.io/about-us/"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('footer::About Us')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
