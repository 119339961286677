import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// App Components
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import Alert from "react-s-alert";
import selectOptions from "../selectOptions";
import { Dropdown, Message } from "semantic-ui-react";
import { Modal } from "react-bootstrap";

export default function EditIssue(props) {
  const { t, i18n } = useTranslation();

  const [visibleMessage, setVisibleMessage] = useState(true);
  const [requiredField, setRequiredField] = useState("");

  const [form, setState] = useState({
    name: "",
    observation: "",
    state: "",
  });

  useEffect(() => {
    if (props.idToEdit) {
  
      FetchService.simpleFetch(
        apiRoutes.ISSUE.DETAIL + "/" + props.idToEdit,
        (data) => {
         
          setState({
            ...form,
            name: data.name ? data.name : "",
            observation: data.observation ? data.observation : "",
            state: data.state ? data.state : "",
          });
        }
      );
    }
  }, [props.idToEdit]);

  let stateList = selectOptions.ISSUE.STATE.map((option) => {
    return { key: option.key, value: option.value, text: t(option.text) };
  });

  const updateField = (e) => {
    setVisibleMessage(true);
    setRequiredField("");
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const isRequired = (name) => {
    if (form[name] === "") {
      setVisibleMessage(false);
      setRequiredField(name);
      return false;
    } else {
      return true;
    }
  };
  let handleChangeSelect = (event, e) => {
    setVisibleMessage(true);
    setRequiredField("");
    setState({
      ...form,
      [e.name]: e.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isRequired("name") && isRequired("state")) {
      const body = {
        name: form.name,
        observation: form.observation,
        state: form.state,
      };

      FetchService.simplePut(
        apiRoutes.ISSUE.EDIT + "/" + props.idToEdit,
        body,
        () => {
          Alert.success(t("messages::Issue has been edited"));
          props.handleModelUpdate();
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.showEdit} onHide={props.handleCloseEditModal}>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t("actions::Edit") + " " + t(props.entityName)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label className="form-control-label" htmlFor="input-edit-name">
                  {t("model::fields::name")}
                </label>
                <input
                  type="text"
                  id="input-edit-name"
                  className="form-control"
                  placeholder={t("model::fields::name")}
                  name="name"
                  value={form.name}
                  onChange={updateField}
                />
              </div>
              <div className="form-group">
                <label
                  className="form-control-label"
                  htmlFor="input-edit-observation"
                >
                  {t("model::fields::observation")}
                </label>
                <input
                  type="text"
                  id="input-edit-observation"
                  className="form-control"
                  name="observation"
                  placeholder={t("model::fields::observation")}
                  value={form.observation}
                  onChange={updateField}
                />
              </div>
              <div className="form-group">
                <label
                  className="form-control-label"
                  htmlFor="input-edit-state"
                >
                  {t("model::fields::state")}
                </label>
                <Dropdown
                  placeholder={t("placeholders::selectState")}
                  name="state"
                  onChange={handleChangeSelect}
                  value={form.state}
                  className="form-control"
                  fluid
                  selection
                  clearable
                  options={stateList}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Message hidden={visibleMessage} negative>
              <Message.Header>{t("messages::Field required")}</Message.Header>
              <p>{t("model::fields::" + requiredField)}</p>
            </Message>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.handleCloseEditModal}
            >
              {t("actions::Close")}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("actions::Submit")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  );
}
