import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// App Components
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import Alert from "react-s-alert";
import selectOptions from "../selectOptions";
import { Dropdown, Message } from "semantic-ui-react";
import RolesGuard from "../RolesGuard";
import { Modal } from 'react-bootstrap';

export default function EditProjectModal(props) {
  const { t, i18n } = useTranslation();
  const [externalUsers, setExternalUsers] = useState([]);
  const [services, setServices] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [visibleMessage, setVisibleMessage] = useState(true);
  const [requiredField, setRequiredField] = useState("");
  const [form, setState] = useState({
    name: "",
    observation: "",
    type: "",
    priority: "",
    reason: "",
    start_date: "",
    state: "",
    informer: "",
    center: "",
    service: "",
  });
  useEffect(() => {
    
    if (props.idToEdit) {
      FetchService.simpleFetch(
        apiRoutes.PROJECT.DETAIL + "/" + props.idToEdit,
        (data) => {
          
          if(RolesGuard.hasAnyRole(['ADMIN', 'INTERNAL']))handleChangeInformer(null, { value: data.idInformer });
          setState({
            ...form,
            name: data.name,
            observation: data.observation,
            type: data.type,
            priority: data.priority,
            reason: data.reason,
            start_date: data.start_date
              ? formatDate(new Date(data.start_date))
              : "",
            state: data.state,
            informer: data.idInformer,
            center: data.idCenter,
            service: data.idService,
          });
        }
      );
    }
  }, [props.idToEdit]);
  useEffect(() => {
   
    FetchService.simpleFetchWithoutAlert(apiRoutes.USER.CENTERS+"?id="+props.idUser, (data) => {
      var centerOptions = [];
      if(data){
        
       
          centerOptions = data.centers.map((center) => {
           
            const container = {};
            container["key"] = center.id;
            container["value"] = center.id;
            container["text"] = center.name;
    
            return container;
          });
        
        setCenterList(centerOptions);
      }else{
      
      }
  });
  }, []);
  useEffect(() => {
    FetchService.simpleFetchWithoutAlert(apiRoutes.USER.EXTERNALUSERS, setExternalUsers);
  }, [props.idToEdit]);
  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.SERVICE.LIST, setServices);
  }, [props.idToEdit]);

  let typeList = selectOptions.PROJECT.TYPE.map((option) => {
    return { key: option.key, value: option.value, text: t(option.text) };
  });
  let priorityList = selectOptions.PROJECT.PRIORITY.map((option) => {
    return { key: option.key, value: option.value, text: t(option.text) };
  });
  let stateList = selectOptions.PROJECT.STATE.map((option) => {
    return { key: option.key, value: option.value, text: t(option.text) };
  });

  let informerList = externalUsers.users
    ? externalUsers.users.map((informer) => {
        const container = {};
        container["key"] = informer.id;
        container["value"] = informer.id;
        container["text"] = informer.username;

        return container;
      })
    : [{ key: "null", value: "", text: "" }];

  let serviceList = services.map((service) => {
    const container = {};
    container["key"] = service.id;
    container["value"] = service.id;
    container["text"] = service.name;

    return container;
  });

  const updateField = (e) => {
    setVisibleMessage(true);
    setRequiredField("");
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  let handleChangeSelect = (event, e) => {
    setVisibleMessage(true);
    setRequiredField("");
    setState({
      ...form,
      [e.name]: e.value,
    });
  };

  let handleChangeInformer = (event, e) => {
    
    setVisibleMessage(true);
    setRequiredField("");
    var selectedUser = externalUsers.users?externalUsers.users.filter(
      (externalUser) => externalUser.id == e.value
    ):"";
    var centerOptions = [];
    if (e.value) {
      centerOptions = selectedUser[0].centers.map((center) => {
        
        const container = {};
        container["key"] = center.id;
        container["value"] = center.id;
        container["text"] = center.name;

        return container;
      });
    }
    setCenterList(centerOptions);
    setState({
      ...form,
      center: "",
    });
    setState({
      ...form,
      [e.name]: e.value,
    });
  };
  const isRequired = (name) => {
    if (form[name] === "") {
      setVisibleMessage(false);
      setRequiredField(name);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      isRequired("name") &&
      isRequired("type") &&
      isRequired("priority") &&
      isRequired("start_date") &&
      isRequired("state") &&
      isRequired("informer") &&
      isRequired("center") &&
      isRequired("service")
    ) {
      const body = {
        name: form.name,
        observation: form.observation,
        type: form.type,
        priority: form.priority,
        reason: form.reason,
        start_date: form.start_date,
        state: form.state,
        idInformer: form.informer,
        idCenter: form.center,
        idService: form.service,
      };

      FetchService.simplePut(
        apiRoutes.PROJECT.EDIT + "/" + props.idToEdit,
        body,
        () => {
          Alert.success(t("messages::Item has been edited"));
          props.handleModelUpdate();
          
        }
      );
    }
  };
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  
  return (
    <React.Fragment>
        <Modal show={props.showEdit} onHide={props.handleCloseEditModal}>
        
          <form onSubmit={handleSubmit}>
            
          <Modal.Header closeButton>
                <Modal.Title >
                  {t("actions::Edit") + " " + t("model::entities::project")}
                </Modal.Title>
                
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-name"
                    >
                      {t("model::fields::code")}
                    </label>
                    <input
                      type="text"
                      id="input-new-name"
                      className="form-control"
                      name="name"
                      placeholder={t("model::fields::code")}
                      value={form.name}
                      onChange={updateField}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-reason"
                    >
                      {t("model::fields::reason")}
                    </label>
                    <textarea
                      type="text"
                      id="input-new-reason"
                      className="form-control"
                      name="reason"
                      placeholder={t("model::fields::reason")}
                      value={form.reason}
                      onChange={updateField}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-start_date"
                    >
                      {t("model::fields::start date")}
                    </label>
                    <input
                      type="date"
                      name="start_date"
                      id="input-new-start_date"
                      className="form-control"
                      placeholder={t("model::fields::start_date")}
                      value={form.start_date}
                      onChange={updateField}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-observation"
                    >
                      {t("model::fields::observation")}
                    </label>
                    <input
                      type="text"
                      name="observation"
                      id="input-new-observation"
                      className="form-control"
                      placeholder={t("model::fields::observation")}
                      value={form.observation}
                      onChange={updateField}
                    />
                  </div>
                  <div className="form-group">
                    <label for="role"> {t("model::fields::type")}</label>
                    <Dropdown
                      placeholder={t("placeholders::type")}
                      name="type"
                      onChange={handleChangeSelect}
                      value={form.type}
                      className="form-control"
                      fluid
                      selection
                      clearable
                      options={typeList}
                    />
                  </div>
                  { RolesGuard.hasRole("EXTERNAL")?"":<div className="form-group">
                    <label for="role"> {t("model::fields::priority")}</label>
                    <Dropdown
                      placeholder={t("placeholders::priority")}
                      onChange={handleChangeSelect}
                      value={form.priority}
                      name="priority"
                      className="form-control"
                      fluid
                      selection
                      clearable
                      options={priorityList}
                    />
                  </div>}
                  <div className="form-group">
                    <label for="state"> {t("model::fields::state")}</label>
                    <Dropdown
                      placeholder={t("placeholders::state")}
                      onChange={handleChangeSelect}
                      value={form.state}
                      name="state"
                      className="form-control"
                      fluid
                      selection
                      clearable
                      options={stateList}
                    />
                  </div>
                  <div className="form-group">
                    <label for="informer">
                      {" "}
                      {t("model::fields::informer")}
                    </label>
                   { RolesGuard.hasRole("EXTERNAL")?<label>{":  "+props.account}</label>:<Dropdown
                      placeholder={t("placeholders::informer")}
                      onChange={handleChangeInformer}
                      value={form.informer}
                      name="informer"
                      className="form-control"
                      fluid
                      search
                      selection
                      clearable
                      options={informerList}
                    />}
                  </div>
                  <div className="form-group">
                    <label for="center"> {t("model::fields::center")}</label>
                    <Dropdown
                      placeholder={t("placeholders::center")}
                      onChange={handleChangeSelect}
                      value={form.center}
                      name="center"
                      className="form-control"
                      fluid
                      search
                      selection
                      clearable
                      options={centerList}
                    />
                  </div>
                  <div className="form-group">
                    <label for="service"> {t("model::fields::service")}</label>
                    <Dropdown
                      placeholder={t("placeholders::service")}
                      name="service"
                      onChange={handleChangeSelect}
                      value={form.service}
                      className="form-control"
                      fluid
                      selection
                      search
                      clearable
                      options={serviceList}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Message hidden={visibleMessage} negative>
                  <Message.Header>
                    {t("messages::Field required")}
                  </Message.Header>
                  <p>{t("model::fields::" + requiredField)}</p>
                </Message>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={props.handleCloseEditModal}
                >
                  {t("actions::Close")}
                </button>
                <button type="submit" className="btn btn-primary">
                  {t("actions::Submit")}
                </button>
              </Modal.Footer>
            
          </form>
       
      </Modal>
    </React.Fragment>
  );
}
