import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import FetchService from "../FetchService";
import RolesGuard from "../RolesGuard";
import $ from "jquery";
// App Components
import Footer from "../components/Footer";

import SimpleEntityTable from "../components/SimpleEntityTable";
import apiRoutes from "../apiRoutes";
import webRoutes from "../webRoutes";
import { Rating } from 'semantic-ui-react'
import Alert from "react-s-alert";

export default function Rate(props) {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(
            <div>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="/"
              >
                {t("menu::Dashboard")} /
              </a>

              <span
                className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="#"
              >
                {t(props.title)}
              </span>
            </div>
          )}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7 fondo">
            <RateContainer {...props} />
            
            {/* <!-- Footer --> */}
           
          </div>
          <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}

function RateContainer(props) {
  const { t, i18n } = useTranslation();
  const [rate, setRate] = useState(0);

  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.USER.GETRATE + "/" + props.idUser,
      (data) => {
       
      setRate(data.points)
      }
    );
  }, []);


  const handleRate = (e, { rating, maxRating }) =>{
    e.preventDefault();
    FetchService.simplePut(
      apiRoutes.USER.EDITRATE + "/" + props.idUser,
      {
        points:rating
      },
      (data) => {
        Alert.success("Valoración correcta");
      
        FetchService.simpleFetch(
          apiRoutes.USER.GETRATE + "/" + props.idUser,
          (data) => {
           
          setRate(data.points)
          }
        );
      }
    );

  }


  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="mb-0">{t("pages::Rate")}</h3>
                </div>
                <div className="col text-right">
                
                  
                </div>
              </div>
            </div>
            <div className="card-body">
            <Rating maxRating={5} size='massive' rating={rate} onRate={handleRate}  icon='star'/>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}
