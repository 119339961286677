import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import FetchService from "../FetchService";
import RolesGuard from "../RolesGuard";
import Alert from "react-s-alert";
import $ from "jquery";
// App Components
import Footer from "../components/Footer";
import apiRoutes from "../apiRoutes";
import {
  Button,
  Comment,
  Form,
  Header,
  Input,
  Confirm,
  Radio,
} from "semantic-ui-react";

export default function Comments(props) {
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState("");
  const [contentReply, setContentReply] = useState("");
  const [contentUpdate, setContentUpdate] = useState("");
  const [idUpdate, setIdUpdate] = useState(null);
  const [idFather, setIdFather] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [records, setRecords] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [privateComment, setPrivateComment] = useState(false);
  const [privateCommentReply, setPrivateCommentReply] = useState(false);

  useEffect(() => {
   
    FetchService.simpleFetch(
      apiRoutes.COMMENTARY.LIST_ISSUE + "/" + props.issueId,
      setRecords
    );
  }, []);
  const handleContent = (event) => {
    setContent(event.target.value);
  };
  const handlePrivateComment = (event) => {
    setPrivateComment(!privateComment);
  };
  const handlePrivateCommentReply = (event) => {
    setPrivateCommentReply(!privateCommentReply);
  };
  const handleContentReply = (event) => {
    setContentReply(event.target.value);
  };
  const handleContentUpdate = (event) => {
    setContentUpdate(event.target.value);
  };
  const handleIdFather = (id, privateComment) => {
    $(".replyDiv").hide("slow");
    $(".updateDiv").hide("slow");
    if ($("#" + id).is(":visible")) {
      $("#" + id).hide("slow");
      setIdFather(null);
      setPrivateCommentReply(false);
    } else {
      $("#" + id).show("slow");
      setIdFather(id);
      setPrivateCommentReply(privateComment);
    }

    setContentReply("");
  };
  const handleUpdate = (comment) => {
    $(".updateDiv").hide("slow");
    $(".replyDiv").hide("slow");
    if ($("#update" + comment.id).is(":visible")) {
      $("#update" + comment.id).hide("slow");
      setContentUpdate("");
      setIdUpdate(null);
    } else {
      $("#update" + comment.id).show("slow");
      setContentUpdate(comment.content);
      setIdUpdate(comment.id);
    }
  };
  const handleIdDelete = (id) => {
    setIdDelete(id);
    setOpenModalDelete(true);
  };
  const deleteComment = () => {
    setOpenModalDelete(false);
    FetchService.simpleDelete(
      apiRoutes.COMMENTARY.DELETE + "/" + idDelete,
      () => {
        Alert.success(t("messages::Item has been removed"));
        FetchService.simpleFetch(
          apiRoutes.COMMENTARY.LIST_ISSUE + "/" + props.issueId,
          setRecords
        );
        setContentUpdate("");
        setIdDelete(null);
      }
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    $(".replyDiv").hide();

    const body = {
      content: content,
      idIssue: props.issueId,
      idUser: props.idUser,
      private: privateComment,
    };

    FetchService.simplePost(
      apiRoutes.COMMENTARY.NEW + "/" + props.issueId,
      body,
      () => {
        Alert.success(t("messages::Item has been added"));
        FetchService.simpleFetch(
          apiRoutes.COMMENTARY.LIST_ISSUE + "/" + props.issueId,
          setRecords
        );
        setContent("");
        setPrivateComment(false);
      }
    );
  };
  const handleSubmitReply = async (event) => {
    event.preventDefault();
    $(".replyDiv").hide();

    const body = {
      content: contentReply,
      idIssue: props.issueId,
      idUser: props.idUser,
      idFather: idFather,
      private: privateCommentReply,
    };

    FetchService.simplePost(
      apiRoutes.COMMENTARY.NEW + "/" + props.issueId,
      body,
      () => {
        Alert.success(t("messages::Item has been added"));
        FetchService.simpleFetch(
          apiRoutes.COMMENTARY.LIST_ISSUE + "/" + props.issueId,
          setRecords
        );
        setContentReply("");
      }
    );
  };
  const handleSubmitUpdate = async (event) => {
    event.preventDefault();
    $(".updateDiv").hide();

    const body = {
      content: contentUpdate,
    };

    FetchService.simplePut(
      apiRoutes.COMMENTARY.UPDATE + "/" + idUpdate,
      body,
      () => {
        Alert.success(t("messages::Item has been edited"));
        FetchService.simpleFetch(
          apiRoutes.COMMENTARY.LIST_ISSUE + "/" + props.issueId,
          setRecords
        );
        setContentUpdate("");
      }
    );
  };
  var comments = commentaryComposeRecursive(records);

  function commentaryComposeRecursive(records) {
    var comments = [];
    records.sort((a, b) =>
      a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
    );

    records.map((comment, index) => {
      var date = new Date(comment.createdAt);

      comments.push(
        <Comment>
          {/* <Comment.Avatar src="/assets/img/avatars/INTERNAL/1.png" /> */}
          <Comment.Avatar
            className="bg-white"
            src={"/assets/img/avatars/" + comment.user.avatar?comment.user.avatar:"avatar2.svg"}
          />
          <Comment.Content>
            <Comment.Author as="a">{comment.user.username}</Comment.Author>
            <Comment.Metadata>
              
              <div>
                {date.toLocaleString()}
                {comment.createdAt != comment.updatedAt ? " -Editado-" : ""}
              </div>
            </Comment.Metadata>
            <Comment.Text className={comment.private ? "privateComment" : ""}>
              {comment.deletedAt ? (
                <div style={{ color: "red" }}>
                  {t("messages::Commentary deleted")}
                </div>
              ) : (
                comment.private && !RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"])?'*******':comment.content
              )}
            </Comment.Text>
            {comment.deletedAt ? (
              ""
            ) : (
              <Comment.Actions>
                <Comment.Action
                  onClick={() => handleIdFather(comment.id, comment.private)}
                >
                  {t("actions::Reply")}
                </Comment.Action>
                {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ||
                comment.user.id === props.idUser ? (
                  <Comment.Action onClick={() => handleUpdate(comment)}>
                    {t("actions::Edit")}
                  </Comment.Action>
                ) : (
                  ""
                )}
                {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ||
                comment.user.id === props.idUser ? (
                  <Comment.Action onClick={() => handleIdDelete(comment.id)}>
                    {t("actions::Delete")}
                  </Comment.Action>
                ) : (
                  ""
                )}
                <div
                  id={"update" + comment.id}
                  className="updateDiv"
                  style={{ display: "none" }}
                >
                  <Form onSubmit={handleSubmitUpdate}>
                    <Form.TextArea
                      name="content"
                      value={contentUpdate}
                      onChange={handleContentUpdate}
                      style={{ borderColor: "orange" }}
                      required
                    />

                    <button
                      type="submit"
                      className="btn btn-sm btn-outline-primary"
                    >
                      <i className="fas fa-toggle-off"></i>
                      &nbsp;&nbsp;{t("actions::Edit")}
                    </button>
                  </Form>
                </div>
                <div
                  id={comment.id}
                  className="replyDiv"
                  style={{ display: "none" }}
                >
                  <Form onSubmit={handleSubmitReply}>
                    <Form.TextArea
                      name="content"
                      value={contentReply}
                      onChange={handleContentReply}
                      style={{ borderColor: "green" }}
                      required
                    />
                    {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"])?comment.private ? (
                      <Form.Group>
                        <Form.Field>
                          <label>{t("model::fields::privateComment")}</label>
                        </Form.Field>
                      </Form.Group>
                    ) : (
                      <Form.Group>
                        <Form.Field>
                          
                          <Radio
                            toggle
                            label={t("model::fields::privateComment")}
                            onChange={handlePrivateCommentReply}
                            checked={privateCommentReply}
                          />
                        </Form.Field>
                      </Form.Group>
                    ):''}
                    <button
                      type="submit"
                      className="btn btn-sm btn-outline-primary"
                    >
                      <i className="fas fa-toggle-off"></i>
                      &nbsp;&nbsp;{t("actions::Reply")}
                    </button>
                  </Form>
                </div>
              </Comment.Actions>
            )}
          </Comment.Content>
          <Comment.Group>
            {comment.comentsReply
              ? commentaryComposeRecursive(comment.comentsReply)
              : ""}
          </Comment.Group>
        </Comment>
      );
    });
    return comments;
  }

  return (
    <div className="card shadow">
      <Confirm
        open={openModalDelete}
        content={t("messages::Are you sure do you want to delete it?")}
        onCancel={() => {
          setOpenModalDelete(false);
          setIdDelete(null);
        }}
        onConfirm={deleteComment}
        style={{ position: "relative" }}
      />

      <div className="card-header border-0">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="mb-0">{t("model::lists::comments")}</h3>
          </div>
        </div>
      </div>
      <div className="card-body">
        <Comment.Group threaded>
          {comments}
          <Form reply onSubmit={handleSubmit}>
            <Form.TextArea
              name="content"
              value={content}
              onChange={handleContent}
              required
            />
            {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"])?<Form.Group>
              <Form.Field>
              
                <Radio
                  toggle
                  label={t("model::fields::privateComment")}
                  onChange={handlePrivateComment}
                  checked={privateComment}
                />
              </Form.Field>
            </Form.Group>:''}

            <button type="submit" className="btn btn-outline-primary">
              <i className="fas fa-toggle-off"></i>
              &nbsp;&nbsp;{t("actions::Comment")}
            </button>
          </Form>
        </Comment.Group>
      </div>
    </div>
  );
}
