import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import FetchService from "../FetchService";
// App Components
import Footer from "../components/Footer";
import { Tab } from "semantic-ui-react";

import apiRoutes from "../apiRoutes";
import CrudNameObservation from "../crud-name-observation/CrudNameObservation";
import Comments from "../comments/Comments";
import Documents from "../documents/Documents";
import Assistances from "../Assistances/Assistances";


export default function IssueDetail(props) {
  const { t, i18n } = useTranslation();
  const [issue, setIssue] = useState();
  const [issuePermission, setIssuePermission] = useState(false);

  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.ISSUE.DETAIL + "/" + props.issueId,
      setIssue
    );
  }, []);
  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.ISSUE.PERMISSION + "/" + props.issueId,
      (data)=>{
        setIssuePermission(data.permission)

      }
    );
  }, []);
  const panes = [
    {
      menuItem: t("model::lists::information"),
      render: () => <IssueGeneralData {...props} />,
    },
    {
      menuItem: t("model::lists::comments"),
      render: () => <Comments {...props}  />,
    },
    {
      menuItem: t("model::lists::assistances"),
      render: () => (
        <Assistances
          pageName={"pages::Assistances"}
          entityName={"model::entities::assistence"}
          apiRoute={apiRoutes.ASSISTANCE}
          issue={issue}
          issueId={props.issueId}
          issuePermission={issuePermission}
        />
      ),
    },
    {
      menuItem: t("model::lists::documents"),
      render: () => <Documents {...props} issuePermission={issuePermission} title="model::lists::documents" response={false}  addButton="actions::AddDocument" message="messages::No documents are listed for this issue"/>,
    },
  ];
  return (
    <React.Fragment>
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          
          {props.setNavbarTop(<div>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="/"
              >
                {t("menu::Dashboard")} /
              </a>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="/projects"
              >
                {t("model::lists::projects")} /
              </a>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="#"
              >
                {t("pages::ProjectDetail")} /
              </a>
              <span
                className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="#"
              >
                {t(props.title)}
              </span>
            </div>)}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7 fondo">
            {/*<ProjectsContainer />*/}
            <Tab
              menu={{ fluid: true, vertical: true }}
              menuPosition="right"
              panes={panes}
            />
            {/* <!-- Footer --> */}
            
          </div>
          <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}

function IssueGeneralData(props) {
  const { t, i18n } = useTranslation();
  const [record, setRecord] = useState();

  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.ISSUE.DETAIL + "/" + props.issueId,
      setRecord
    );
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header bg-transparent">
              <h1 className="mb-0">{t("model::lists::information")}</h1>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::name")}
                </label>
                {record ? record.name : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::observation")}
                </label>
                {record ? record.observation : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::status")}
                </label>
                {record && record.state ? t("selectOptions::issue::state::"+record.state) : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
