import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import $ from "jquery";
// App Components
import Footer from "../components/Footer";
import FetchService from "../FetchService";
import RolesGuard from "../RolesGuard";
import apiRoutes from "../apiRoutes";
import webRoutes from "../webRoutes";
import AuthService from "../AuthService";
import NewIssue from "./IssueNew";
import EditIssue from "./IssueEdit";

// react-bootstrap-table2
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import cellEditFactory from "react-bootstrap-table2-editor";
// Other
import Alert from "react-s-alert";

export default function Issues(props) {
  const { t, i18n } = useTranslation();

  const [filter, setFilter] = useState(false);
  const [idToEdit, setIdToEdit] = useState();
  const [modelUpdatesCounter, setModelUpdatesCounter] = useState(0);
  const [isCollaborator, setIsCollaborator] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showNew, setShowNew] = useState(false);

  useEffect(() => {
    const decodedUserToken = AuthService.getDecodedUserToken();
    if (decodedUserToken && decodedUserToken.id) {
      FetchService.simplePost(
        apiRoutes.COLLABORATION.ISCOLLABORATOR,
        { idUser: decodedUserToken.id, idProject: props.projectId },
        (data) => {
          if (data.data.count != 0) setIsCollaborator(true);
        }
      );
    }
  }, []);

  const handleFilter = (event) => {
    setFilter(!filter);
  };

  const handleModelUpdate = () => {
  
    setModelUpdatesCounter(modelUpdatesCounter + 1);
    setShowEdit(false);
    setShowNew(false);
  };
const handleCloseEditModal = () => setShowEdit(false);
  const handleOpenEditModal = (event) => {
    setShowEdit(true);
  };
  const handleOpenNewModal = () => setShowNew(true);
  const handleCloseNewModal = () => setShowNew(false);
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="mb-0">{t("pages::Issues")}</h3>
                </div>
                <div className="col text-right">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={handleFilter}
                  >
                    {filter ? (
                      <i className="fas fa-toggle-on"></i>
                    ) : (
                      <i className="fas fa-toggle-off"></i>
                    )}
                    &nbsp;&nbsp;{t("actions::Filter")}
                  </button>
                  &nbsp;&nbsp;
                  {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ||
                  props.idInformer == props.idUser ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      data-toggle="modal"
                      onClick={handleOpenNewModal}
                    >
                      <i className="fas fa-plus"></i>&nbsp;&nbsp;
                      {t("actions::New")}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="card-body">
              <IssuesTable
              handleOpenEditModal={handleOpenEditModal}
                modelUpdatesCounter={modelUpdatesCounter}
                handleModelUpdate={handleModelUpdate}
                filter={filter}
                setIdToEdit={setIdToEdit}
                entityName={props.entityName}
                projectId={props.projectId}
              />
            </div>

            {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ||
            props.idInformer == props.idUser ? (
              <NewIssue
                handleModelUpdate={handleModelUpdate}
                projectId={props.projectId}
                showNew={showNew}
                handleCloseNewModal={handleCloseNewModal}
              />
            ) : (
              ""
            )}
            <EditIssue
             showEdit={showEdit}
              handleModelUpdate={handleModelUpdate}
              idToEdit={idToEdit}
              entityName={props.entityName}
              handleCloseEditModal={handleCloseEditModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function IssuesTable(props) {
  const { t, i18n } = useTranslation();
  const [records, setRecords] = useState();

  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.ISSUE.LIST + "/" + props.projectId,
      setRecords
    );
  }, [props.modelUpdatesCounter]);

  const handleDelete = async (event) => {
    const id = event.target.getAttribute("data-id");

    if (window.confirm(t("messages::Are you sure do you want to delete it?"))) {
      FetchService.simpleDelete(apiRoutes.ISSUE.DELETE + "/" + id, () => {
        // Show in app a user friendly message.
        Alert.success(t("messages::Item has been removed"));
        // Update last deleted Id to update UI.
        props.handleModelUpdate();
      });
    }
  };

  const handleEdit = (event) => {
    const id = event.target.getAttribute("data-id");
    props.handleOpenEditModal();
    props.setIdToEdit(id);
  };

  const columns = [
    {
      dataField: "name",
      text: t("model::fields::name"),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder: t("actions::Enter") + " " + t("model::fields::name"),
          })
        : null,
    },
    {
      dataField: "observation",
      text: t("model::fields::observation"),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder:
              t("actions::Enter") + " " + t("model::fields::observation"),
          })
        : null,
    },
    {
      dataField: "state",
      text: t("model::fields::state"),
      sort: true,
      formatter: stateFormatter,
      filter: props.filter
        ? textFilter({
            placeholder: t("actions::Enter") + " " + t("model::fields::state"),
          })
        : null,
    },
    {
      dataField: "id",
      text: t("actions::Actions"),
      sort: false,
      formatter: actionsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "30em" };
      },
    },
  ];

  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp; {from} - {to} &nbsp;
      <small className="text-muted">[Total: {size}]</small>
    </span>
  );

  const pagination = paginationFactory({
    sizePerPage: 10,
    showTotal: true,
    paginationTotalRenderer: paginationTotalRenderer,
  });

  const cellEdit = cellEditFactory({
    mode: "dbclick",
    beforeSaveCell: (oldValue, newValue, row, column, done) => {
      setTimeout(() => {
        if (window.confirm(t("messages::Do you want to accep this change?"))) {
          done(); // contine to save the changes
          FetchService.simplePut(
            apiRoutes.ISSUE.EDIT + "/" + row.id,
            {
              [column.dataField]: newValue,
            },
            () => {
              Alert.success(t("messages::Issue has been edited"));
            }
          );
        } else {
          done(false); // reject the changes
        }
      }, 0);
      return { async: true };
    },
    afterSaveCell: (oldValue, newValue, row, column) => {},
  });
  function stateFormatter(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return t("selectOptions::issue::state::" + cell);
    }
  }

  function actionsFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="text-right">
        <React.Fragment>
          <a
            type="button"
            data-id={cell}
            className="btn btn-outline-primary btn-sm"
            href={webRoutes.ISSUE.DETAIL + "/" + cell}
          >
            <i className="fas fa-eye"></i>&nbsp;
            {t("actions::Detail")}
          </a>
          <button
            type="button"
            data-id={cell}
            className="btn btn-outline-primary btn-sm"
            data-toggle="modal"
            data-target="#edit-modal"
            onClick={handleEdit}
          >
            <i className="fas fa-pen-alt"></i>&nbsp;
            {t("actions::Edit")}
          </button>
          <button
            type="button"
            data-id={cell}
            className="btn btn-outline-danger btn-sm"
            onClick={handleDelete}
          >
            <i className="fas fa-times-circle"></i>&nbsp;
            {t("actions::Delete")}
          </button>
        </React.Fragment>
      </div>
    );
  }

  return (
    <React.Fragment>
      
      {records && records.length ? (
        <div className="table-responsive" style={{ marginBottom: "50px" }}>
          <BootstrapTable
            keyField="id"
            data={records}
            columns={columns}
            caption={props.caption}
            bootstrap4={true}
            headerWrapperClasses="thead-light"
            bordered={false}
            hover
            condensed
            filter={filterFactory()}
            pagination={pagination}
            cellEdit={cellEdit}
          />
        </div>
      ) : (
        <div className="text-center">
          <div className="alert alert-secondary" role="alert">
            {records ? (
              <strong>{t("literals::No data")}</strong>
            ) : (
              <div className="text-center">
                <img
                  src="../spinner.gif"
                  width="100"
                  alt=""
                  style={{ opacity: "0.5" }}
                />
                <div className="text-muted">
                  <i>{t("literals::Loading")}</i>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
