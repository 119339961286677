import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import FetchService from "../FetchService";
import Issues from "../issues/Issues";
import RolesGuard from "../RolesGuard";

// App Components
import Footer from "../components/Footer";
import { Tab } from "semantic-ui-react";

import apiRoutes from "../apiRoutes";
import CrudNameObservation from "../crud-name-observation/CrudNameObservation";
import Collaborations from "../Collaborations/Collaborations";
import Comments from "../comments/Comments";
import Documents from "../documents/Documents";

export default function ProjectDetail(props) {
  const { t, i18n } = useTranslation();
  const panes = [
    {
      menuItem: t("model::lists::information"),
      render: () => <ProjectGeneralData {...props} />,
    },
    {
      menuItem: t("model::lists::issues"),
      render: () => (
        <Issues
          pageName={"pages::Issues"}
          entityName={"model::entities::issue"}
          apiRoute={apiRoutes.ISSUE}
          projectId={props.projectId}
          idInformer={props.idInformer}
        />
      ),
    },
    {
      menuItem: t("model::lists::collaborations"),
      render: () => (
        <Collaborations
          pageName={"pages::Collaborations"}
          entityName={"model::entities::collaboration"}
          apiRoute={apiRoutes.COLLABORATION}
          projectId={props.projectId}
          idInformer={props.idInformer}
        />
      ),
    },
    {
      menuItem: t("model::lists::response"),
      render: () => (
        <Documents
          {...props}
          title="model::lists::response"
          addButton="actions::AddResponse"
          message="messages::No response are listed for this project"
          response={true}
        />
      ),
    },
    // { menuItem: t('model::lists::documents'), render: () =>  <Documents {...props}/> },
    // { menuItem: t('model::lists::comments'), render: () =>  <Comments {...props}/> }
  ];
  return (
    <React.Fragment>
     
      {props.account ? (
        <div className="main-content" >
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(
            <div>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="/"
              >
                {t("menu::Dashboard")} /
              </a>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="/projects"
              >
                {t("model::lists::projects")} /
              </a>
              <span
                className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="#"
              >
                {t(props.title)}
              </span>
            </div>
          )}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7 fondo">
            {/*<ProjectsContainer />*/}
            <Tab
              menu={{ fluid: true, vertical: true }}
              menuPosition="right"
              panes={panes}
            />
            {/* <!-- Footer --> */}
           
          </div>
          <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}
function ProjectGeneralData(props) {
  const { t, i18n } = useTranslation();
  const [record, setRecord] = useState();

  const handleDownload = () => {
    FetchService.simpleFetchFiles(
      apiRoutes.PROJECT.TEMPLATE + "/" + record.id,
      record.name + "-" + new Date().toLocaleString()+".docx"
    );
  };

  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.PROJECT.DETAIL + "/" + props.projectId,
      setRecord
    );
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header bg-transparent">
              <h1 className="mb-0">{t("model::lists::information")}</h1>
            </div>
            <div className="card-body">
              {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ? (
                <div className="form-group">
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleDownload()}
                  >
                    {t("actions::DownloadTemplate")}
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::code")}
                </label>
                {record ? record.name : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::observation")}
                </label>
                {record ? record.observation : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::status")}
                </label>
                {record
                  ? t("selectOptions::project::state::" + record.state)
                  : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::type")}
                </label>
                {record && record.type
                  ? t("selectOptions::project::types::" + record.type)
                  : ""}
              </div>
              {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ? (
                <div className="form-group">
                  <label className="form-control-label mr-3">
                    {t("model::fields::priority")}
                  </label>
                  {record && record.priority
                    ? t("selectOptions::project::priority::" + record.priority)
                    : ""}
                </div>
              ) : (
                ""
              )}
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::reason")}
                </label>
                {record && record.reason ? record.reason : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::start date")}
                </label>
                {record && record.start_date ? formatDate(record.start_date) : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::service")}
                </label>
                {record && record.service? record.service.name : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::center")}
                </label>
                {record && record.center ? record.center.name : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::manager")}
                </label>
                {record && record.manager ? record.manager.username : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::informer")}
                </label>
                {record && record.informer ? record.informer.username : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function formatDate(dateStr) {
  const d = new Date(dateStr);
  return (
    d.getDate().toString().padStart(2, "0") +
    "/" +
    (parseInt(d.getMonth()) + 1) +
    "/" +
    d.getFullYear()
  );
}

{
  /*
function ProjectsContainer() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className='row'>
        <div className='col'>
          <div className='card shadow'>
            <div className='card-header bg-transparent'>
              <h1 className='mb-0'>{t('model::lists::projects')}</h1>
            </div>
            <div className='card-body'>
              <SimpleEntityTable entityName='Tabla Sespa: NINGUNA' apiRoute={apiRoutes.BRAND.LIST} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
*/
}
