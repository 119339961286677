import React from 'react';
import { useTranslation } from 'react-i18next';
// App Components
import NavbarTop from '../NavbarTop';
import Footer from '../components/Footer';



export default function Tutorial(props) {
	const { t, i18n } = useTranslation();
	return (
		<div className="main-content">
			{/* <!-- Navbar --> */}
			{props.setNavbarTop(t(props.title))}
			{/* <!-- End Navbar --> */}
			{/* <!-- Header --> */}
			<div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
			<div className="container-fluid mt--7">
				<TutorialContainer />
				{/* <!-- Footer --> */}
				<Footer />
			</div>
		</div>
	);
}

function TutorialContainer() {
	const { t, i18n } = useTranslation();
	return (
		<div>
			<div className="row">
				<div className="col">
					<div className="card shadow">
						<div className="card-header bg-transparent">
							<h1 className="mb-0">{t('pages::Tutorial')}</h1>
						</div>
						<div className="card-body">
							<h3>Puedes descargarte el tutorial de la aplicación en el siguiente enlace: <a href='https://drive.google.com/file/d/1NetsZqnkzo7p5XLuQOeDyz1x07APFCNd/view?usp=sharing' target='_blank' rel='noopener noreferrer'>Tutorial</a></h3>
							
							</div>
							</div>
				</div>
			</div>
		</div>
	);
}
