import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// App Components
import FetchService from '../FetchService';
import apiRoutes from '../apiRoutes';
import Alert from 'react-s-alert';

export default function EditDocumentModal(props) {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState('');
  const [observation, setObservation] = useState('');

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleObservation = (event) => {
    setObservation(event.target.value);
  };

  useEffect(() => {
    if (props.idToEdit) {
      FetchService.simpleFetch(apiRoutes.DOCUMENT.DETAIL + '/' + props.idToEdit, (data) => {
        setName(data.name ? data.name : "");
        setObservation(data.observation ? data.observation: "");
      });
    }
  }, [props.idToEdit]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      name: name,
      observation: observation,
    };

    FetchService.simplePut(props.issueId ? apiRoutes.DOCUMENT.EDIT+ '/' + props.idToEdit+'/'+props.issueId:apiRoutes.DOCUMENT.EDITRESPONSE+ '/' + props.idToEdit, body, () => {
      Alert.success(t('messages::Item has been edited'));
      props.handleModelUpdate();
    });
  };

  return (
    <React.Fragment>
      <div
        className='modal fade'
        id='edit-document-modal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='edit-modal-label'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <form onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='edit-modal-label'>
                  {t('actions::Edit') + ' ' + t(props.entityName)}
                </h5>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div>
                  <div className='form-group'>
                    <label className='form-control-label' htmlFor='input-edit-name'>
                      {t('model::fields::name')}
                    </label>
                    <input
                      type='text'
                      id='input-edit-name'
                      className='form-control'
                      placeholder={t('model::fields::name')}
                      value={name}
                      onChange={handleName}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='form-control-label' htmlFor='input-edit-observation'>
                      {t('model::fields::observation')}
                    </label>
                    <input
                      type='text'
                      id='input-edit-observation'
                      className='form-control'
                      placeholder={t('model::fields::observation')}
                      value={observation}
                      onChange={handleObservation}
                    />
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' data-dismiss='modal'>
                  {t('actions::Close')}
                </button>
                <button type='submit' className='btn btn-primary'>
                  {t('actions::Submit')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
