import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
// App Components
import Footer from '../components/Footer';
import ServiceTable from './ServiceTable';
import NewServiceModal from './NewServiceModal';
import EditCenterModal from './EditCenterModal';

export default function CrudService(props) {
  const { t, i18n } = useTranslation();

  const [filter, setFilter] = useState(false);
  const [idToEdit, setIdToEdit] = useState();
  const [modelUpdatesCounter, setModelUpdatesCounter] = useState(0);

  const handleFilter = (event) => {
    setFilter(!filter);
  };

  const handleModelUpdate = () => {
    setModelUpdatesCounter(modelUpdatesCounter + 1);
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="mb-0">{t(props.pageName)}</h3>
                </div>
                <div className="col text-right">
                
                  <button type="button" className="btn btn-outline-primary" onClick={handleFilter}>
                    {filter ? <i className="fas fa-toggle-on"></i> : <i className="fas fa-toggle-off"></i>}
                    &nbsp;&nbsp;{t('actions::Filter')}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target="#new-modal"
                  >
                    <i className="fas fa-plus"></i>&nbsp;&nbsp;
                    {t('actions::New')}
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ServiceTable
                apiRoute={props.apiRoute}
                webRoute={props.webRoute}
                modelUpdatesCounter={modelUpdatesCounter}
                handleModelUpdate={handleModelUpdate}
                setIdToEdit={setIdToEdit}
                filter={filter}
                entityName={props.entityName}
                projectId={props.projectId}
              />
            </div>
           
            <NewServiceModal
              handleModelUpdate={handleModelUpdate}
              apiRoute={props.apiRoute}
              entityName={props.entityName}
              projectId={props.projectId}
            />
            <EditCenterModal
              handleModelUpdate={handleModelUpdate}
              idToEdit={idToEdit}
              apiRoute={props.apiRoute}
              entityName={props.entityName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
