import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// App Components
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import Alert from "react-s-alert";
// react-bootstrap-table2
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Modal } from "react-bootstrap";

export default function NewAssistanceModal(props) {
  const { t, i18n } = useTranslation();

  
  const [assistance, setAssistance] = useState([]);
  const [records, setRecords] = useState([]);
  

  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.USER.ASSISTANUSERS+"/"+props.issueId, setRecords);
  }, [props.modelUpdatesCounter]);
  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.ASSISTANCE.LIST_ASSISTANCE + "/" + props.issueId,
      setAssistance
    );
  }, [props.modelUpdatesCounter]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const id = event.target.getAttribute("data-id");
    let user_detail;
    records.users.map((user) => {
      if(user.id == id){
        user_detail = user;
      }
    });

    let body = {
      idUser: id,
      idIssue: props.issueId,
    };

    FetchService.simplePost(apiRoutes.ASSISTANCE.NEW, body, (response) => {
      if (response.status == 200) {

        
        Alert.success(t("messages::Assistance has been added"));

        if (user_detail.email == "alvaro.arias@fundacionctic.org") {
          
        body = {
            from: 'alvaro.arias@fundacionctic.org',
            to: user_detail.email,
            subject: '[ASISTENTE INCIDENCIA] ' + props.issue.name,
            text: "Has sido añadido como asistente en la incidencia: " + props.issue.name,
          }

        FetchService.simplePost(apiRoutes.MAIL.SENDMAIL, body, (response) => {
          if (response.status == 200){
            Alert.success("Correo enviado a: " + user_detail.email);
          }
          else{
            Alert.error("Error enviando el correo a: " + user_detail.email)
          }
        })
        

        }
      }
      else {
        Alert.error("No se ha podido agregar a: " + user_detail.username)
      }

      props.handleModelUpdate();
      
    });
  };
  const columns = [
    {
      dataField: "username",
      text: t("model::fields::name"),
      sort: true,
      filter:
        textFilter({
            placeholder: t("actions::Enter") + " " + t("model::fields::name"),
          })
        ,
    },

    {
      dataField: "id",
      text: t("actions::Actions"),
      sort: false,
      formatter: actionsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "20em" };
      },
    },
  ];
  function actionsFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="text-right">
        
        <React.Fragment>
          <button
            type="button"
            data-id={cell}
            className="btn btn-outline-danger btn-sm"
            onClick={handleSubmit}
          >
            {t("actions::Add")}
          </button>
        </React.Fragment>
      </div>
    );
  }
  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp; {from} - {to} &nbsp;
      <small className="text-muted">[Total: {size}]</small>
    </span>
  );

  let assistanceList = [];
  if (records.users) {
   
    records.users.map((externalUser) => {
      if (assistance.filter((d) => d.idUser == externalUser.id).length == 0) {
        assistanceList.push(externalUser);
      }
    });
  }
  const pagination = paginationFactory({
    sizePerPage: 10,
    showTotal: true,
    paginationTotalRenderer: paginationTotalRenderer,
  });
  return (
    <React.Fragment>
       <Modal show={props.showNew} onHide={props.handleCloseNewModal}>
        
         
       <Modal.Header closeButton>
       <Modal.Title>
                {t("actions::Add") + " " + t(props.entityName)}
                </Modal.Title>
              
            </Modal.Header>
            <Modal.Body>
              <BootstrapTable
                keyField="id"
                data={assistanceList}
                columns={columns}
                caption={props.caption}
                bootstrap4={true}
                headerWrapperClasses="thead-light"
                bordered={false}
                hover
                condensed
                filter={filterFactory()}
                pagination={pagination}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={props.handleCloseNewModal}
              >
                {t("actions::Close")}
              </button>
            </Modal.Footer>
         
       
      </Modal>
    </React.Fragment>
  );
}
