import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
// App Components
import Footer from "../components/Footer";
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import RolesGuard from "../RolesGuard";
import {
  List,
  Card,
  Icon,
  Segment,
  Header,
  Button,
  Dropdown,
} from "semantic-ui-react";
import NewDocumentModal from "./NewDocumentModal";
import EditDocumentModal from "./EditDocumentModal";
import Alert from "react-s-alert";
import $ from "jquery";

export default function Documents(props) {
  const { t, i18n } = useTranslation();
  const [modelUpdatesCounter, setModelUpdatesCounter] = useState(0);
  const [records, setRecords] = useState([]);
  const [idToEdit, setIdToEdit] = useState();
  const [idToVersion, setIdToVersion] = useState();
  const [project, setProject] = useState();

  useEffect(() => {
    if (props.issueId) {
      FetchService.simpleFetch(
        apiRoutes.DOCUMENT.LIST + "?idIssue=" + props.issueId,
        setRecords
      );
    }
    if (props.projectId) {
      FetchService.simpleFetch(
        apiRoutes.DOCUMENT.LIST + "?idProject=" + props.projectId,
        setRecords
      );
    }
    if(props.response){
      FetchService.simpleFetch(
        apiRoutes.PROJECT.DETAIL + "/" + props.projectId,
        setProject
      );
    }
  }, [modelUpdatesCounter]);

  const handleModelUpdate = () => {
    setModelUpdatesCounter(modelUpdatesCounter + 1);
    $("#new-document-modal").hide();
    $("#edit-document-modal").hide();
    $(".modal-backdrop").remove();
    setIdToEdit();
    setIdToVersion();
  };
  const handleDelete = (id) => {
    if (window.confirm(t("messages::Are you sure do you want to delete it?"))) {
      FetchService.simpleDelete(props.issueId ? apiRoutes.DOCUMENT.DELETE + "/" + id + '/' +props.issueId : apiRoutes.DOCUMENT.DELETERESPONSE + "/" + id, () => {
        // Show in app a user friendly message.
        Alert.success(t("messages::Item has been removed"));
        // Update last deleted Id to update UI.

        // FetchService.simpleFetch(
        //   apiRoutes.DOCUMENT.LIST + "?idIssue=" + props.issueId,
        //   setRecords
        // );
        setModelUpdatesCounter(modelUpdatesCounter + 1);
      });
    }
  };
  const handleDownload = (id, name) => {
    FetchService.simpleFetchFiles(props.issueId ? apiRoutes.DOCUMENT.DOWNLOAD + "/" + id + '/' +props.issueId: apiRoutes.DOCUMENT.DOWNLOADRESPONSE + "/" + id + '/' +props.projectId, name);
  };

  const handleEdit = (id) => {
    setIdToEdit(id);
  };
  const handleVersion = (id) => {
    setIdToVersion(id);
  };
  

  const handleDownloadTemplate = () => {
    FetchService.simpleFetchFiles(
      apiRoutes.PROJECT.TEMPLATE + "/" + project.id,
      project.name + "-" + new Date().toLocaleString()+".docx"
    );
  };

  
  return (
    <div className="card shadow">
      <div className="card-header border-0">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="mb-0">{t(props.title)}</h3>
          </div>
          <div className="col text-right">
          {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) && props.response ? (
                
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleDownloadTemplate()}
                  >
                    {t("actions::DownloadTemplate")}
                  </button>
               
              ) : (
                ""
              )}
            {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ||(!props.response &&
            props.issuePermission)? (
              <button
                type="button"
                className="btn btn-outline-primary"
                data-toggle="modal"
                data-target="#new-document-modal"
                onClick={() => handleVersion()}
              >
                <i className="fas fa-plus"></i>&nbsp;&nbsp;
                {t("actions::New")}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        {records.length === 0 ? (
          <Segment placeholder>
            <Header style={{ width: "auto", height: "auto" }} icon>
              <Icon name="pdf file outline" />
              {t(props.message)}
            </Header>
          
            {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ||(!props.response &&
            props.issuePermission)? (
              <Button
                primary
                data-toggle="modal"
                data-target="#new-document-modal"
              >
                {t(props.addButton)}
              </Button>
            ) : (
              ""
            )}
          </Segment>
        ) : (
          <Card.Group centered>
            {records.map((document) => {
              var lastDocument;
              var array = [];
              array = document.documentVersion;
              if (!document.documentVersion) {
                lastDocument = JSON.parse(JSON.stringify(document));
              } else {
                array = JSON.parse(JSON.stringify(document.documentVersion));
                array.push(document);
                array.sort((a, b) =>
                  a.createdAt > b.createdAt
                    ? 1
                    : b.createdAt > a.createdAt
                    ? -1
                    : 0
                );
                lastDocument = array[array.length - 1];
                array.splice(array.length - 1);
              }

              return (
                <Card>
                  <Card.Content header={lastDocument.name} />
                  <Card.Content description={lastDocument.observation} />
                  <Card.Content
                    description={
                      t("model::fields::created") +
                      ": " +
                      new Date(lastDocument.createdAt).toLocaleString()
                    }
                  />
                  <Card.Content extra>
                    <a
                      onClick={() =>
                        handleDownload(
                          lastDocument.id,
                          lastDocument.originalName
                        )
                      }
                    >
                      <Icon name="arrow alternate circle down" />
                      {lastDocument.originalName}
                    </a>
                  </Card.Content>
                  <Card.Content>
                    {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"])||(!props.response &&
            props.issuePermission) ? (
                      <div className="ui two buttons">
                        <button
                          type="button"
                          data-id={lastDocument.id}
                          className="btn btn-outline-primary btn-sm"
                          data-toggle="modal"
                          data-target="#edit-document-modal"
                          onClick={() => handleEdit(lastDocument.id)}
                        >
                          <i className="fas fa-pen-alt"></i>&nbsp;
                          {t("actions::Edit")}
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => handleDelete(lastDocument.id)}
                        >
                          <i className="fas fa-times-circle"></i>&nbsp;
                          {t("actions::Delete")}
                        </button>
                        <button
                          type="button"
                          data-id={lastDocument.id}
                          className="btn btn-outline-primary btn-sm"
                          data-toggle="modal"
                          data-target="#new-document-modal"
                          onClick={() =>
                            handleVersion(
                              lastDocument.idParent
                                ? lastDocument.idParent
                                : lastDocument.id
                            )
                          }
                        >
                          <i className="fas fa-pen-alt"></i>&nbsp;
                          {t("actions::AddVersion")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {array.length != 0 ? (
                      <Dropdown scrolling text={t("actions::Versions")}>
                        <Dropdown.Menu>
                          {array.map((element, index) => {
                            return (
                              <Dropdown.Item>
                                <Card>
                                  <Card.Content
                                    header={"V-" + index + "  " + element.name}
                                  />
                                  <Card.Content
                                    description={element.observation}
                                  />
                                  <Card.Content
                                    description={
                                      t("model::fields::created") +
                                      ": " +
                                      new Date(
                                        element.createdAt
                                      ).toLocaleString()
                                    }
                                  />
                                  <Card.Content extra>
                                    <a
                                      onClick={() =>
                                        handleDownload(
                                          element.id,
                                          element.originalName
                                        )
                                      }
                                    >
                                      <Icon name="arrow alternate circle down" />
                                      {element.originalName}
                                    </a>
                                  </Card.Content>
                                </Card>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </Card.Content>
                </Card>
              );
            })}
          </Card.Group>
        )}
      </div>
      {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ||(!props.response &&
            props.issuePermission)? (
        <NewDocumentModal
          handleModelUpdate={handleModelUpdate}
          apiRoute={props.apiRoute}
          entityName={props.entityName}
          issueId={props.issueId}
          projectId={props.projectId}
          idUser={props.idUser}
          idParent={idToVersion}
        />
      ) : (
        ""
      )}
      {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ||(!props.response &&
            props.issuePermission)? (
        <EditDocumentModal
          handleModelUpdate={handleModelUpdate}
          idToEdit={idToEdit}
          issueId={props.issueId}
        />
      ) : (
        ""
      )}
    </div>
  );
}
