import React from 'react';
import { useTranslation } from 'react-i18next';
// App Components
import NavbarTop from '../NavbarTop';
import Footer from '../components/Footer';



export default function GettingStarted(props) {
	const { t, i18n } = useTranslation();
	return (
		<div className="main-content">
			{/* <!-- Navbar --> */}
			{props.setNavbarTop(t(props.title))}
			{/* <!-- End Navbar --> */}
			{/* <!-- Header --> */}
			<div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
			<div className="container-fluid mt--7">
				<GettingStartedContainer />
				{/* <!-- Footer --> */}
				<Footer />
			</div>
		</div>
	);
}

function GettingStartedContainer() {
	const { t, i18n } = useTranslation();
	return (
		<div>
			<div className="row">
				<div className="col">
					<div className="card shadow">
						<div className="card-header bg-transparent">
							<h1 className="mb-0">{t('pages::Getting Started')}</h1>
						</div>
						<div className="card-body">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut risus eros. Morbi commodo sollicitudin ante quis volutpat. Donec elementum pretium rhoncus. Vivamus pulvinar eros vel quam rhoncus, fermentum varius risus ultricies. Pellentesque tincidunt lorem sed massa tincidunt porta. Suspendisse sit amet velit mauris. Proin quis lorem metus. Suspendisse lectus sapien, luctus porta fringilla a, elementum ut est. Nunc sit amet metus ex. Nunc a sollicitudin orci, vitae commodo ex. Quisque auctor eleifend viverra. Maecenas rutrum purus nec luctus imperdiet. Sed dolor odio, pellentesque quis dignissim in, sagittis vel nulla.</p>
							<p>Sed pharetra nisi vitae metus iaculis fermentum. Praesent non elementum erat. Vivamus id imperdiet enim. Praesent ultrices nec ante id convallis. Ut in velit mauris. Curabitur orci lectus, lacinia sit amet justo sit amet, pretium lobortis nibh. Quisque convallis nibh non nisi condimentum tincidunt.</p>
							<p>Pellentesque scelerisque euismod velit non tempus. Proin tincidunt leo mi, sed varius dolor egestas eget. Donec vitae justo dapibus, viverra erat eu, mattis augue. Phasellus magna lacus, aliquam sit amet ultrices venenatis, pharetra eu augue. Nulla tempor odio neque, ut cursus turpis ullamcorper non. Quisque accumsan porttitor nunc eget pharetra. Ut semper viverra eros, sed bibendum diam ornare a.</p>
							<p>Suspendisse potenti. Donec ac scelerisque augue, in efficitur quam. Phasellus enim enim, lobortis a pulvinar at, fringilla vel orci. Nullam eleifend, elit vitae lacinia finibus, orci lectus feugiat nunc, vitae eleifend ligula lorem at ligula. Morbi facilisis rutrum erat, non suscipit neque finibus id. Curabitur lacus diam, varius ut molestie ac, auctor eu libero. Fusce convallis odio nisi, in pulvinar tortor mattis non.</p>
							<p>Quisque porttitor rutrum mauris, et aliquet libero tempus in. Aenean ultricies purus ut arcu gravida, sit amet malesuada ligula pulvinar. Aliquam ultrices viverra felis, id egestas sapien sagittis eu. Suspendisse dignissim felis ut enim faucibus, ut placerat justo lobortis. Vestibulum tincidunt quam lacus, quis placerat leo fermentum in. Aliquam vitae dui pharetra, sagittis arcu ut, euismod nisi. Vivamus fermentum urna vel leo malesuada, in pellentesque neque accumsan. Sed a egestas leo. Fusce porta faucibus nisi, auctor tempor risus blandit ac. Aliquam erat volutpat. Vestibulum interdum, ante in interdum malesuada, diam ante ultrices risus, ac tincidunt justo leo sed turpis. Pellentesque ut ipsum vel sapien pellentesque tempor et commodo orci. Vestibulum dolor ante, tempor rhoncus magna et, ullamcorper pharetra quam. Aenean porta non nisi vel tempor. Morbi facilisis purus lectus, ut accumsan purus suscipit pellentesque.</p>
						</div>
					</div>
				</div>
			</div>



		</div>
	);
}
