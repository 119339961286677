import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import UsersEdit from "./UsersEdit";
import Alert from "react-s-alert";
import UsersNew from "./UsersNew";
import $ from "jquery";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Rating } from 'semantic-ui-react';

export default function UsersTable(props) {
  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.USER.LIST, setRecords);
  }, []);

  let actionButtons = (cell, row) => {
    return (
      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#editUsersModal"
            onClick={() => updateUser(row)}
          >
            Actualizar
          </button>
        </div>
        <div className="col">
          <button
            className="btn btn-primary"
            onClick={() => removeUser(row.id)}
          >
            Eliminar
          </button>
        </div>
      </div>
    );
  };
  let statusFormat = (cell, row) => {
    var status = "Activo"
    if(cell === 0){
      status = "Inactivo"
    }
    return (
      status
    );
  };
  let rolesFormat = (cell, row) => {
    var roles = ""
    cell.map((role)=>{
      roles+= " - " + role.name;
    })
    return (
      roles
    );
  };

  let rateFormat = (cell, row) => {
    
    return (
      <Rating maxRating={5}  rating={cell}   icon='star' disabled/>
    );
  };

  let updateUser = (row) => {
    setSelected(row);
  };

  let removeUser = (id) => {
    if (window.confirm(t("messages::confirmDelete"))) {
      FetchService.simpleDelete(
        apiRoutes.USER.DELETE+"/"+id,
        (data) => {
          Alert.success("Usuario borrado con exito");
          FetchService.simpleFetch(apiRoutes.USER.LIST, setRecords);
        }
      );
    }
  };

  function handleChange() {
    FetchService.simpleFetch(apiRoutes.USER.LIST, setRecords);
    $("#newUsersModal").hide();
    $("#editUsersModal").hide();
    $(".modal-backdrop").remove();
  }
  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp; {from} - {to} &nbsp;<small className="text-muted">[Total: {size}]</small>
    </span>
  );
  const pagination = paginationFactory({
    sizePerPage: 10,
    showTotal: true,
    paginationTotalRenderer: paginationTotalRenderer,
  });

  
  const columns = [
    
    {
      dataField: "username",
      text: "Username",
      sort: true,
    },
    
    {
      dataField: "status",
      text: "Estado",
      sort: true,
      formatter: statusFormat,
    },
    {
      dataField: "roles",
      text: "Rol",
      sort: true,
      formatter: rolesFormat,
    },
    {
      dataField: "points",
      text: "Valoración",
      sort: true,
      formatter: rateFormat,
    },
    {
      dataField: "lol",
      text: "",
      formatter: actionButtons,
    },
  ];

  return (
    <div
      className="container-fluid table-responsive"
      style={{ marginBottom: "50px" }}
    >
      
      <EditUserModal
        {...props}
        selected={selected}
        onChange={handleChange}
      />
       <NewUsersModal {...props}  onChange={handleChange}/>
    
       <BootstrapTable
            keyField="id"
            data={records}
            columns={columns}
            caption={props.caption}
            bootstrap4={true}
            headerWrapperClasses="thead-light"
            bordered={false}
            hover
            condensed
            filter={filterFactory()}
            pagination={pagination}
            
          />
      
    </div>
  );
}
function EditUserModal(props) {
  const { t } = useTranslation();
  return (
    <div
      className="modal fade"
      id="editUsersModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("actions::Edit")}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <UsersEdit {...props} />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              {t("actions::Close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
function NewUsersModal(props) {
  const { t } = useTranslation();
  return (
    <div
      className="modal fade"
      id="newUsersModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
            {t('actions::Add')}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <UsersNew {...props}/>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              {t("actions::Close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}