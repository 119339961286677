import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import Footer from "../components/Footer";
import UsersTable from "./UsersTable";


export default function Users(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(
            <div>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="/"
              >
                {t("menu::Dashboard")} /
              </a>
              
              <span
                className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="#"
              >
                {t(props.title)}
              </span>
            </div>
          )}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7 fondoAdmin">
            <UsersContainer {...props} />
           
           
          </div>
           {/* <!-- Footer --> */}
           <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}

function UsersContainer() {
  const { t } = useTranslation();
 

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h3 className="mb-0">{t("model::lists::users")}</h3>
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#newUsersModal"
              >
                Nuevo
              </button>
            </div>
            <UsersTable />
          </div>
        </div>
      </div>
    </div>
  );
}

