import React, { Component, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthService from './AuthService';
import RolesGuard from './RolesGuard';

export default function NavbarVertical(props) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const decodedUserToken = AuthService.getDecodedUserToken();
    if (!decodedUserToken || !decodedUserToken.username) {
      props.logout();
    }
    if (AuthService.isTokenExpired()) {
      props.logout();
    }
  }, []);

  return (
    <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
      <div className="container-fluid">
        {/* <!-- Toggler --> */}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#sidenav-collapse-main"
          aria-controls="sidenav-main"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <!-- Brand --> */}
        <Link className="navbar-brand pt-0" to="/">
          <img src="/assets/img/brand/logo.png" className="navbar-brand-img" alt="..." />
        </Link>

        {/* <!-- User --> */}
        {props.account != null ? (
          <ul className="nav align-items-center d-md-none">
            {/*
						<li className='nav-item dropdown'>
              <a
                className='nav-link nav-link-icon'
                href='#'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <i className='ni ni-bell-55'></i>
              </a>
              <div
                className='dropdown-menu dropdown-menu-arrow dropdown-menu-right'
                aria-labelledby='navbar-default_dropdown_1'
              >
                <a className='dropdown-item' href='#'>
                  Action
                </a>
                <a className='dropdown-item' href='#'>
                  Another action
                </a>
                <div className='dropdown-divider'></div>
                <a className='dropdown-item' href='#'>
                  Something else here
                </a>
              </div>
						</li>
						 */}
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="Image placeholder" src="/assets/img/icons/common/user.png" />
                  </span>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                <div className=" dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </div>
                <a href="#" className="dropdown-item">
                  <i className="ni ni-single-02"></i>
                  <span>{t('navbarTop::My profile')}</span>
                </a>
                <a href="#" className="dropdown-item">
                  <i className="ni ni-settings-gear-65"></i>
                  <span>{t('navbarTop::Settings')}</span>
                </a>
                <a href="#" className="dropdown-item">
                  <i className="ni ni-calendar-grid-58"></i>
                  <span>{t('navbarTop::Activity')}</span>
                </a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item" onClick={() => props.logout()}>
                  <i className="ni ni-user-run"></i>
                  <span>{t('navbarTop::Logout')}</span>
                </a>
              </div>
            </li>
          </ul>
        ) : (
          <ul className="nav align-items-center d-md-none">
            <li className="nav-item">
              <Link className="nav-link pr-0" to="/login">
                <div className="media align-items-center">
                  <i className="fas fa-sign-in-alt"></i>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm  font-weight-bold" style={{ lineHeight: '36px' }}>
                      {t('navbarTop::Login')}
                    </span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        )}

        {/* <!-- Collapse --> */}
        <div className="collapse navbar-collapse" id="sidenav-collapse-main">
          {/* <!-- Collapse header --> */}
          <div className="navbar-collapse-header d-md-none">
            <div className="row">
              <div className="col-6 collapse-brand">
                <Link className=" nav-link" to="/">
                  <img src="/assets/img/brand/logo.png" alt="" />
                </Link>
              </div>
              <div className="col-6 collapse-close">
                <button
                  type="button"
                  className="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#sidenav-collapse-main"
                  aria-controls="sidenav-main"
                  aria-expanded="false"
                  aria-label="Toggle sidenav"
                >
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          {/* <!-- Form --> */}
          {/*
					<form className="mt-4 mb-3 d-md-none">
						<div className="input-group input-group-rounded input-group-merge">
							<input type="search" className="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search" />
							<div className="input-group-prepend">
								<div className="input-group-text">
									<span className="fa fa-search"></span>
								</div>
							</div>
						</div>
					</form>
					*/}
          {/* <!-- Navigation --> */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className=" nav-link" to="/">
                <i className="ni ni-tv-2 text-primary"></i>
                {t('menu::Dashboard')}
              </Link>
            </li>
          </ul>
          {props.account ? (
            <React.Fragment>
              {/* <!-- Divider --> */}
              <hr className="my-3" />
              {/* <!-- Heading --> */}
              <h6 className="navbar-heading text-muted">{t('menu::Issues')}</h6>
              {/* <!-- Navigation --> */}
              <ul className="navbar-nav">
                {/* <li className="nav-item">
                  <Link className=" nav-link" to="/issues">
                    <i className="fas fa-tasks text-primary"></i>
                    {t('menu::Issues')}
                  </Link>
                </li> */}
                {RolesGuard.hasAnyRole(['ADMIN','INTERNAL','EXTERNAL'])?<li className="nav-item">
                  <Link className=" nav-link" to="/projects">
                    <i className="fas fa-dna text-primary"></i>
                    {t('menu::Projects')}
                  </Link>
                </li>:""}
                {RolesGuard.hasRole('ASSISTANT')?<li className="nav-item">
                  <Link className=" nav-link" to="/issuesByAssistant">
                    <i className="fas fa-dna text-primary"></i>
                    {t('menu::Issues')}
                  </Link>
                </li>:""}
                
              </ul>
              {/* <!-- Divider --> */}
              {RolesGuard.hasAnyRole(['ADMIN'])?<div>
              <hr className="my-3" />
              {/* <!-- Heading --> */}
              <h6 className="navbar-heading text-muted">{t('menu::Management')}</h6>
              {/* <!-- Navigation --> */}
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className=" nav-link" to="/services">
                    <i className="fas fa-first-aid text-primary"></i>
                    {t('menu::Services')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className=" nav-link" to="/centers">
                    <i className="fas fa-hospital text-primary"></i>
                    {t('menu::Centers')}
                  </Link>
                </li>
                {RolesGuard.hasAnyRole(['ADMIN'])?<li className="nav-item">
                  <Link className=" nav-link" to="/users">
                    <i className="fas fa-user-md text-primary"></i>
                    {t('menu::Users')}
                  </Link>
                </li>:""}
              </ul>
              </div>:""}
            </React.Fragment>
          ) : null}

          {/* <!-- Divider --> */}
          <hr className="my-3" />
          {/* <!-- Heading --> */}
          <h6 className="navbar-heading text-muted">{t('menu::Documentation')}</h6>
          {/* <!-- Navigation --> */}
          <ul className="navbar-nav mb-md-3">
            {/* <li className="nav-item">
              <Link className=" nav-link" to="/gettingstarted">
                <i className="ni ni-spaceship text-primary"></i>
                {t('menu::Getting Started')}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className=" nav-link" to="/tutorial">
                <i className="ni ni-palette text-primary"></i>
                {t('menu::Tutorial')}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className=" nav-link" to="/about">
                <i className="ni ni-ui-04 text-primary"></i>
                {t('menu::About')}
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
