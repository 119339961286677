import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import FetchService from "../FetchService";
import Issues from "../issues/Issues";
import RolesGuard from "../RolesGuard";

// App Components
import Footer from "../components/Footer";
import { Tab, Dropdown } from "semantic-ui-react";
import Alert from "react-s-alert";

import apiRoutes from "../apiRoutes";
import CrudNameObservation from "../crud-name-observation/CrudNameObservation";
import Collaborations from "../Collaborations/Collaborations";
import Comments from "../comments/Comments";
import Documents from "../documents/Documents";
import RequestCenterModal from '../centers/RequestCenterModal';
import avatars from "../avatars";

export default function UserDetail(props) {
  const { t, i18n } = useTranslation();
  
  return (
    <React.Fragment>
    
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(
            <div>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="/"
              >
                {t("menu::Dashboard")} /
              </a>
             
              <span
                className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="#"
              >
                {t(props.title)}
              </span>
            </div>
          )}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7">
            {/*<ProjectsContainer />*/}
            <UserData {...props} avatars={avatars} />
            {/* <!-- Footer --> */}
            <Footer />
            <RequestCenterModal
      
              apiRoute={props.apiRoute}
              entityName={props.entityName}
              idUser={props.idUser}
            />
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}
function UserData(props) {
  const { t, i18n } = useTranslation();
  const [record, setRecord] = useState();

  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.USER.DETAIL + "/" + props.idUser,
      setRecord
    );
  }, []);
  let avatarsList = record?props.avatars[record.roles[0].name].map((avatar, index) => {
    const container = {};
    container["key"] = index;
    container["value"] = avatar;
    container["text"] = <img   alt='Image placeholder' src={'/assets/img/avatars/'+avatar} />;

    return container;
  }):[];

  let handleSubmit = async (e, event) => {
    e.preventDefault();

    FetchService.simplePut(
      apiRoutes.USER.EDITAVATAR + "/" + record.id,
      {
        avatar:event.value
      },
      (data) => {
        Alert.success("Avatar cambiado con exito");
        props.setAvatar(event.value)
        FetchService.simpleFetch(
          apiRoutes.USER.DETAIL + "/" + props.idUser,
          setRecord
        );
      }
    );
  };
  return (
    <div>
      
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header bg-transparent">
              <h1 className="mb-0">{t("model::lists::myProfile")}</h1>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::username")}
                </label>
                {record ? record.username : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::email")}
                </label>
                {record ? record.email : ""}
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::lists::roles")}
                </label>
                <ul>
                {record
                  ? record.roles.map((rol) => {
                      return <li>{rol.name}</li>;
                    })
                  : ""}
                  </ul>
              </div>
              <div className="form-group">
                <label className="form-control-label mr-3">
                  {t("model::fields::centers")}
                </label>
                <ul>
                {record
                  ? record.centers.map((center) => {
                      return <li>{center.name}</li>;
                    })
                  : ""}
                  </ul>
              </div>
             
              <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target="#request-modal"
                  >
                    <i className="fas fa-plus"></i>&nbsp;&nbsp;
                    {t('actions::Request center')}
                  </button>
                  <div >
        <label for="role"> {t("model::fields::avatar")}</label>
        <div className="row">
        <Dropdown
          placeholder={t("placeholders::selectAvatar")}
           onChange={handleSubmit}
          value={record?record.avatar:''}
        
         selection
         
          options={avatarsList}
        />
        </div>
      </div>
    
                  {/* {record?props.avatars[record.roles[0].name].map((avatar)=>{
                  
                    if(record.avatar == avatar){
                      return ( <div className="form-group">
                      <label className="form-control-label mr-5"><img   alt='Image placeholder' src={'/assets/img/avatars/'+avatar} />
                      </label></div>)
                    }else{
                      return ( <div className="form-group">
                <label className="form-control-label mr-3"><img alt='Image placeholder' src={'/assets/img/avatars/'+avatar} />
                </label></div>)
                    }
                
              }):""} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function formatDate(dateStr) {
  const d = new Date(dateStr);
  return (
    d.getDate().toString().padStart(2, "0") +
    "/" +
    (parseInt(d.getMonth()) + 1) +
    "/" +
    d.getFullYear()
  );
}

{
  /*
function ProjectsContainer() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className='row'>
        <div className='col'>
          <div className='card shadow'>
            <div className='card-header bg-transparent'>
              <h1 className='mb-0'>{t('model::lists::projects')}</h1>
            </div>
            <div className='card-body'>
              <SimpleEntityTable entityName='Tabla Sespa: NINGUNA' apiRoute={apiRoutes.BRAND.LIST} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
*/
}
