import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// App Components
import NavbarTop from '../NavbarTop';
import HeaderInfoWidgets from '../components/HeaderInfoWidgets';
import Footer from '../components/Footer';
import ProjectTable from "../projects/ProjectTable";
import NewProjectModal from "../projects/NewProjectModal";
import EditProjectModal from "../projects/EditProjectModal";
import IssuesAssistantTable from "../issues/IssuesAssistantTable";
import RolesGuard from '../RolesGuard';
import SimpleEntityTable from '../components/SimpleEntityTable';
import Alert from 'react-s-alert';
import FetchService from '../FetchService';
import apiRoutes from '../apiRoutes';
import webRoutes from '../webRoutes';
import CrudNameObservation from '../crud-name-observation/CrudNameObservation';

export default function Dashboard(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className="main-content">
      {/* <!-- Navbar --> */}

      {/* <NavbarTop pageTitle={t('Welcome')} {...props} /> */}
      {props.setNavbarTop(t(props.title))}

      {/* <!-- End Navbar --> */}
      {/* <!-- Header --> */}
      {props.account ? <HeaderInfoWidgets /> : <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>}

      <div className="container-fluid mt--7 fondo">
        {props.account ? <DashboardContainer {...props} /> : <DashboardContainerNotLogged />}

        
      </div>
      {/* <!-- Footer --> */}
      <Footer />
    </div>
  );
}

function DashboardContainer(props) {
  const { t, i18n } = useTranslation();

  return (
    <div>
    {RolesGuard.hasAnyRole(['ADMIN', 'INTERNAL', 'EXTERNAL'])?<ProjectsContainer {...props} />:""}
    {RolesGuard.hasRole('ASSISTANT')?<IssuesAssistantTable {...props}/>:""}
    </div>
   
  );
}

function DashboardContainerNotLogged() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header bg-transparent">
              <h1 className="mb-0 pt-4 display-3 text-center text-primary">{t('Welcome')}</h1>
              <div className="lead text-gray text-center">
                <strong>{t('APPTitle')}</strong>
              </div>
            </div>
            <div className="card-body text-center">
              <Link className="btn btn-primary btn-lg" to="/login" style={{ marginRight: '0' }}>
                {t('dashboard::Sign in MYOMICS')}
              </Link>
              <div className="mt-5">
                <img
                  alt="Image placeholder"
                  src="/assets/img/doctorsTable.jpeg"
                  style={{ maxWidth: '100%', width: '600px' }}
                />
              </div>
            </div>
            <div className="card-footer text-center">
              <Link className="" to="/about">
                {t('dashboard::More info')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function ProjectsContainer(props) {
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState(false);
  const [idToEdit, setIdToEdit] = useState();
  const [modelUpdatesCounter, setModelUpdatesCounter] = useState(0);

  const handleFilter = (event) => {
    setFilter(!filter);
  };

  const handleModelUpdate = () => {
    setModelUpdatesCounter(modelUpdatesCounter + 1);
  };
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="mb-0">{t("pages::Projects")}</h3>
                </div>
                <div className="col text-right">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={handleFilter}
                  >
                    {filter ? (
                      <i className="fas fa-toggle-on"></i>
                    ) : (
                      <i className="fas fa-toggle-off"></i>
                    )}
                    &nbsp;&nbsp;{t("actions::Filter")}
                  </button>
                  &nbsp;&nbsp;
                  {RolesGuard.hasAnyRole(['ADMIN', 'INTERNAL'])?<button
                    type="button"
                    className="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target="#new-modal"
                  >
                    <i className="fas fa-plus"></i>&nbsp;&nbsp;
                    {t("actions::New")}
                  </button>:""}
                </div>
              </div>
            </div>
            <div className="card-body">
              <ProjectTable
                modelUpdatesCounter={modelUpdatesCounter}
                handleModelUpdate={handleModelUpdate}
                setIdToEdit={setIdToEdit}
                filter={filter}
                hideDetailLink={props.hideDetailLink}
              />
            </div>
            {RolesGuard.hasAnyRole(['ADMIN', 'INTERNAL'])?<NewProjectModal
              handleModelUpdate={handleModelUpdate}
              
            />:""}
            {RolesGuard.hasAnyRole(['ADMIN', 'INTERNAL'])?<EditProjectModal
              handleModelUpdate={handleModelUpdate}
              idToEdit={idToEdit}
             
            />:""}
          </div>
        </div>
      </div>
    </div>
  );
}
