const apiRoutes = {
  ROOT: "/v1",
  USER: {
    LOGIN: "/v1/user/login",
    LOGOUT: "/v1/user/logout",
    LIST: "/v1/user/list",
    EDIT: "/v1/user",
    EDITAVATAR: "/v1/editAvatar",
    DETAIL: "/v1/user",
    ROLES: "/v1/user/roles",
    NEW: "/v1/user/new",
    DELETE: "/v1/user",
    EXTERNALUSERS: "/v1/externalUsers/list",
    ASSISTANUSERS: "/v1/assistantUsers/list",
    CENTERS: "/v1/centersUser/list",
    GETRATE: "/v1/getRate",
    EDITRATE: "/v1/editRate",
  },
  PROJECT: {
    NEW: "/v1/project/new",
    LIST: "/v1/project/list",
    LIST_COUNT: "/v1/project/list-count",
    DETAIL: "/v1/project/detail",
    EDIT: "/v1/project/edit",
    DELETE: "/v1/project/delete",
    TEMPLATE: "/v1/project/template",
  },
  ISSUE: {
    NEW: "/v1/issue/new",
    LIST: "/v1/issue/list",
    LIST_COUNT: "/v1/issue/list-count",
    DETAIL: "/v1/issue/detail",
    EDIT: "/v1/issue/edit",
    DELETE: "/v1/issue/delete",
    BYASSISTANT: "/v1/issue/byAssistand/list",
    PERMISSION: "/v1/issue/permission"
  },
  COMMENTARY: {
    LIST_ISSUE: "/v1/commentary/commentary-issue",
    NEW: "/v1/commentary/new",
    UPDATE: "/v1/commentary/edit",
    DELETE: "/v1/commentary/delete",
  },
  COLLABORATION: {
    LIST: "/v1/collaboration/collaboration-project",
    NEW: "/v1/collaboration/new",
    DELETE: "/v1/collaboration/delete",
    ISCOLLABORATOR: "/v1/isCollaborator",
  },
  ASSISTANCE: {
    LIST_ASSISTANCE: "/v1/assistance/assistance-issue",
    NEW: "/v1/assistance/new",
    DELETE: "/v1/assistance/delete",
    ISASSISTANT: "/v1/isAssistant",
  },
  DOCUMENT: {
    LIST: "/v1/document/list",
    NEW: "/v1/document/new",
    NEWRESPONSE: "/v1/response/new",
    DOWNLOADRESPONSE: "/v1/response/download/file",
    DOWNLOAD: "/v1/document/download/file",
    DELETERESPONSE: "/v1/response/delete",
    DELETE: "/v1/document/delete",
    DETAIL: "/v1/document/detail",
    EDITRESPONSE: "/v1/response/edit",
    EDIT: "/v1/document/edit",
  },
  SERVICE: {
    NEW: "/v1/service/new",
    LIST: "/v1/service/list",
    LIST_COUNT: "/v1/service/list-count",
    DETAIL: "/v1/service/detail",
    EDIT: "/v1/service/edit",
    DELETE: "/v1/service/delete",
  },
  CENTER: {
    NEW: "/v1/center/new",
    LIST: "/v1/center/list",
    LIST_COUNT: "/v1/center/list-count",
    DETAIL: "/v1/center/detail",
    EDIT: "/v1/center/edit",
    DELETE: "/v1/center/delete",
  },
  MAIL: {
    SENDMAIL: "/v1/mail/sendMail",
  },
};

export default apiRoutes;
