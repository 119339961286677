import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// App Components
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import Alert from "react-s-alert";

export default function NewServiceModal(props) {
  const { t, i18n } = useTranslation();

  const [form, setState] = useState({
    name: "",
    code: "",
    observation: "",
  });
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      name: form.name,
      code: form.code,
      observation: form.observation,
      idProject: props.projectId,
    };

    FetchService.simplePost(props.apiRoute.NEW, body, () => {
      Alert.success(t("messages::Item has been added"));
      props.handleModelUpdate();
    });
  };

  return (
    <React.Fragment>
      <div
        className="modal fade"
        id="new-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="new-modal-label"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="new-modal-label">
                  {t("actions::Add") + " " + t(props.entityName)}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-code"
                    >
                      {t("model::fields::code")}
                    </label>
                    <input
                      type="text"
                      id="input-new-code"
                      className="form-control"
                      placeholder={t("model::fields::code")}
                      value={form.code}
                      onChange={updateField}
                      name="code"
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-name"
                    >
                      {t("model::fields::name")}
                    </label>
                    <input
                      type="text"
                      id="input-new-name"
                      className="form-control"
                      placeholder={t("model::fields::name")}
                      value={form.name}
                      onChange={updateField}
                      name="name"
                    />
                  </div>

                  

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-observation"
                    >
                      {t("model::fields::observation")}
                    </label>
                    <input
                      type="text"
                      id="input-new-observation"
                      className="form-control"
                      placeholder={t("model::fields::observation")}
                      value={form.observation}
                      onChange={updateField}
                      name="observation"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {t("actions::Close")}
                </button>
                <button type="submit" className="btn btn-primary">
                  {t("actions::Submit")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
