import apiRoutes from './apiRoutes';
import AuthService from './AuthService';
import Alert from 'react-s-alert';
import axios from 'axios';

class FetchService {
  // Fetch with a React function to set the state of a component.
  // Use only in basic requests to directly assign the value of variables.
  simpleFetch = async (url, setStateFunction) => {
    url = window.CONFIG.SERVER_BASE_URL + url;

    const fetchOptions = {
      method: 'GET',
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await fetch(url, fetchOptions);
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(data.message);
      }
      setStateFunction(data);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  };
  simpleFetchWithoutAlert = async (url, setStateFunction) => {
    url = window.CONFIG.SERVER_BASE_URL + url;

    const fetchOptions = {
      method: 'GET',
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await fetch(url, fetchOptions);
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(data.message);
      }
      setStateFunction(data);
    } catch (error) {
      console.log('Error: ' + error.message);
    }
  };

  // Axios GET with generic callback
  async simpleGet(endpoint, callback) {
    const url = window.CONFIG.SERVER_BASE_URL + endpoint;

    const options = {
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await axios.get(url, options);
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      // Call callback function with the response object.
      if (callback) callback(response);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  }

  // Axios POST with body and generic callback
  simplePost = async (endpoint, body, callback) => {
    const url = window.CONFIG.SERVER_BASE_URL + endpoint;

    const options = {
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await axios.post(url, body, options);
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      // Show in console the response data from server.
      console.log(response && response.data ? response.data : 'No response.');
      // Call callback function with the response object.
      if (callback) callback(response);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  };

  // Axios PUT with body and generic callback
  simplePut = async (endpoint, body, callback) => {
    const url = window.CONFIG.SERVER_BASE_URL + endpoint;

    const options = {
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await axios.put(url, body, options);
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      // Show in console the response data from server.
      console.log(response && response.data ? response.data : 'No response.');
      // Call callback function with the response object.
      if (callback) callback(response);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  };

  // Axios DELETE with generic callback
  simpleDelete = async (endpoint, callback) => {
    const url = window.CONFIG.SERVER_BASE_URL + endpoint;

    const options = {
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await axios.delete(url, options);
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      // Show in console the response data from server.
      console.log(response && response.data ? response.data : 'No response.');
      // Call callback function with the response object.
      if (callback) callback(response);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  };

  simpleFetchFiles = async (url, originalName) => {
    url = window.CONFIG.SERVER_BASE_URL + url;

    const fetchOptions = {
      method: "GET",
      headers: AuthService.getAuthHeader(),
    };

    try {
       fetch(url, fetchOptions)
       .then(response => response.blob())
       .then(blob => {
         
           var url = window.URL.createObjectURL(blob);
           var a = document.createElement('a');
           a.href = url;
           a.download = originalName;
           document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
           a.click();    
           a.remove();  //afterwards we remove the element again         
       });
       
     
      
    } catch (error) {
      Alert.error("Error: " + error.message);
    }
  };
  

  simplePostFile = async (endpoint, body, callback) => {
   
    const url = window.CONFIG.SERVER_BASE_URL + endpoint;
    let formData = new FormData();
    Object.keys(body).forEach(function (key) {
      formData.append(key, body[key]);
    });

   
    const options = {
      headers: AuthService.getAuthHeader(),
      "Content-Type": "multipart/form-data",
    };

    try {
      const response = await axios.post(url, formData, options);
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      // Show in console the response data from server.
      console.log(response && response.data ? response.data : "No response.");
      // Call callback function with the response object.
      if (callback) callback(response);
    } catch (error) {
      
      Alert.error("Error: " + error.response.data.message);
    }
  };
}

export default new FetchService();
