import React from 'react';
import { useTranslation } from 'react-i18next';
// App Components
import NavbarTop from '../NavbarTop';
import Footer from '../components/Footer';



export default function About(props) {
	const { t, i18n } = useTranslation();
	return (
		<div className="main-content">
			{/* <!-- Navbar --> */}
			{props.setNavbarTop(t(props.title))}
			{/* <!-- End Navbar --> */}
			{/* <!-- Header --> */}
			<div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
			<div className="container-fluid mt--7">
				<AboutContainer />
				{/* <!-- Footer --> */}
				<Footer />
			</div>
		</div>
	);
}

function AboutContainer() {
	const { t, i18n } = useTranslation();
	return (
		<div>
			<div className="row">
				<div className="col">
					<div className="card shadow">
						<div className="card-header bg-transparent">
							<h1 className="mb-0">{t('pages::About')}</h1>
						</div>
						<div className="card-body">
							<p>Praesent ut tristique arcu. Quisque sagittis egestas tellus, ac molestie leo faucibus sit amet. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent condimentum in eros vitae vestibulum. Curabitur vulputate ex justo. Nulla facilisi. Maecenas a magna non orci malesuada sagittis. Sed eleifend sem nibh, at euismod lacus congue vel. Nullam ornare maximus eleifend. Integer luctus imperdiet sagittis. Fusce mattis tempus ex, sed fermentum mi consequat vitae. Morbi fringilla fringilla leo non venenatis. Nullam in elit nec augue vestibulum pellentesque.</p>
							<p>Nullam facilisis sodales urna, vel iaculis lacus sodales in. Donec tincidunt pulvinar eleifend. Etiam tristique pulvinar tortor at volutpat. Aenean sed augue a arcu mollis interdum at at quam. Pellentesque non justo sit amet massa aliquam facilisis at eget neque. Sed ut nunc augue. Morbi egestas quis erat lacinia efficitur. Aliquam ante arcu, feugiat in rutrum eget, pulvinar eu tortor. Duis nec purus porttitor, sagittis nibh a, condimentum lacus. Quisque non ultrices odio, et venenatis diam. Morbi lobortis euismod faucibus. Maecenas sit amet turpis eu arcu egestas egestas.</p>
							<p>Praesent vitae nunc libero. Aenean eget tempor libero, nec pharetra urna. Quisque at cursus nulla. Quisque porta vitae augue vehicula efficitur. Fusce non fermentum nisi, et laoreet purus. Phasellus pulvinar eleifend diam, vitae tincidunt sem dapibus vel. Sed in magna luctus, lacinia mauris non, rutrum nisl.</p>
							<p>Phasellus vel tellus ut lectus porttitor bibendum. Curabitur urna eros, venenatis nec erat eu, ultricies dictum metus. Vestibulum molestie eu purus ornare pulvinar. Ut eu posuere est. Mauris semper viverra auctor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus eu feugiat diam. Integer quis sapien massa.</p>
							<p>Aliquam vitae condimentum justo. Vivamus nunc justo, condimentum non suscipit vel, sagittis quis turpis. Vivamus sem mi, consequat nec risus non, posuere facilisis eros. Phasellus ut dolor nec nisi sagittis euismod. Donec iaculis tristique laoreet. Sed et tincidunt neque. Aliquam in rhoncus dolor. Duis volutpat ex quis malesuada faucibus. Ut in porttitor quam. Morbi mauris risus, semper et condimentum quis, sollicitudin eget nulla.</p>
						</div>
					</div>
				</div>
			</div>



		</div>
	);
}
