import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useParams } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'semantic-ui-css/semantic.min.css';
// App Components
import NavbarVertical from './NavbarVertical';
import NavbarTop from './NavbarTop';
import Login from './Login';
import Dashboard from './dashboard/Dashboard';
import Issues from './issues/Issues';
import IssueDetail from './issues/IssueDetail';
import Projects from './projects/Projects';
import ProjectDetail from './projects/ProjectDetail';
import Users from './users/Users';
import QrReader from './qr-reader/QrReader';
import GettingStarted from './documentation/GettingStarted';
import Tutorial from './documentation/Tutorial';
import About from './documentation/About';
import AuthService from './AuthService';
import RolesGuard from './RolesGuard';
import apiRoutes from './apiRoutes';
import Services from './services/Services';
import Centers from './centers/Centers';
import CenterDetail from './centers/CenterDetail';
import IssuesAssistant from './issues/IssuesAssistant';
import UserDetail from './users/UserDetail';
import Rate from './rate/Rate';

// Other Packages
// Images and CSS
import logo from './logo.svg';
import './App.css';
// Configurations
import devConfig from './config.dev.json';
import prodConfig from './config.prod.json';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      accountProfile: null,
      idUser: null,
      avatar: null,
      setAvatar:this.setAvatar
    };

    switch (process.env.NODE_ENV) {
      case 'production':
        // console.log('PRODUCTION CONFIGURATION:');
        // console.log(prodConfig);
        window.CONFIG = prodConfig;
        break;
      case 'development':
        // console.log('DEVELOPMENT CONFIGURATION:');
        // console.log(devConfig);
        window.CONFIG = devConfig;
        break;
      default:
        // console.log('DEFAULT CONFIGURATION:');
        // console.log(devConfig);
        window.CONFIG = devConfig;
    }

    this.setLoggedAccount = this.setLoggedAccount.bind(this);
    this.setNavbarTop = this.setNavbarTop.bind(this);
    this.setIdUser = this.setIdUser.bind(this);
    this.setAvatar = this.setAvatar.bind(this);
  }

  componentDidMount = async () => {
    const decodedUserToken = AuthService.getDecodedUserToken();
    if (decodedUserToken && decodedUserToken.username) {
      if (AuthService.isTokenExpired()) {
        this.logout();
      } else {
        this.setLoggedAccount(decodedUserToken.username);
      }
    }
    
    if (decodedUserToken && decodedUserToken.id && !AuthService.isTokenExpired()) {
      this.setIdUser(decodedUserToken.id);
    }
    if (decodedUserToken && decodedUserToken.avatar && !AuthService.isTokenExpired()) {
      this.setAvatar(decodedUserToken.avatar);
    }

    const url = window.CONFIG.SERVER_BASE_URL + apiRoutes.ROOT;
    try {
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(data.message);
      }
    } catch (error) {
      Alert.error('Error fetching server Info: ' + error.message);
    }
  };

  setLoggedAccount = (account) => {
    this.setState({ account: account });
  };

  setIdUser = (idUser) => {
    this.setState({ idUser: idUser });
  };

  setAvatar = (avatar) => {
    this.setState({ avatar: avatar });
  };

  logout = () => {
    this.setState({ account: null });
    AuthService.logOut();
  };

  setNavbarTop = (title) => {
    return <NavbarTop pageTitle={title} logout={this.logout} {...this.state} />;
  };

  render() {
    return (
      <Suspense fallback={<Loader />}>
        <Router>
          <Route
            exact
            path="/login"
            render={() => <Login {...this.state} setLoggedAccount={this.setLoggedAccount} />}
          />
          <Route
            exact
            path="/"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <Dashboard {...this.state} setNavbarTop={this.setNavbarTop} title="Welcome" />}
              />
            )}
          />
          <Route
            exact
            path="/issues"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <Issues {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Issues" />}
              />
            )}
          />
          <Route
            exact
            path="/issues/detail/:issueId"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => (
                  <IssueDetail
                    {...this.state}
                    issueId={useParams().issueId}
                    setNavbarTop={this.setNavbarTop}
                    title="pages::IssueDetail"
                  />
                )}
              />
            )}
          />
          <Route
            exact
            path="/projects/detail/:projectId"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => (
                  <ProjectDetail
                    {...this.state}
                    projectId={useParams().projectId}
                    setNavbarTop={this.setNavbarTop}
                    title="pages::ProjectDetail"
                  />
                )}
              />
            )}
          />
          <Route
            exact
            path="/projects"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <Projects {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Projects" />}
              />
            )}
          />
          <Route
            exact
            path="/services"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <Services {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Services" />}
              />
            )}
          />
          <Route
            exact
            path="/centers"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <Centers {...this.state} idUser={this.state.idUser} setNavbarTop={this.setNavbarTop} title="pages::Centers" />}
              />
            )}
          />
          <Route
            exact
            path="/centers/detail/:centerId"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => (
                  <CenterDetail
                    {...this.state}
                    centerId={useParams().centerId}
                    setNavbarTop={this.setNavbarTop}
                    title="pages::CenterDetail"
                  />
                )}
              />
            )}
          />
          <Route
            exact
            path="/qr-reader"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <QrReader {...this.state} setNavbarTop={this.setNavbarTop} title="pages::QR Reader" />}
              />
            )}
          />
          <Route
            exact
            path="/gettingstarted"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => (
                  <GettingStarted {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Getting Started" />
                )}
              />
            )}
          />
          <Route
            exact
            path="/tutorial"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <Tutorial {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Tutorial" />}
              />
            )}
          />
          <Route
            exact
            path="/about"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <About {...this.state} setNavbarTop={this.setNavbarTop} title="pages::About" />}
              />
            )}
          />
          <Route
            exact
            path="/users"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <Users {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Users" />}
              />
            )}
          />
          <Route
            exact
            path="/issuesByAssistant"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <IssuesAssistant {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Issues" />}
              />
            )}
          />
          <Route
            exact
            path="/profile"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <UserDetail {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Profile" />}
              />
            )}
          />
          <Route
            exact
            path="/rate"
            render={() => (
              <PageComposer
                logout={this.logout}
                {...this.state}
                page={() => <Rate {...this.state} setNavbarTop={this.setNavbarTop} title="pages::Rate" />}
              />
            )}
          />
        </Router>
        <Alert stack={{ limit: 5 }} position="top-right" effect="slide" html={true} timeout={3000} offset={40} />
      </Suspense>
    );
  }
}

export default App;

function PageComposer(props) {
  return (
    <div>
      <NavbarVertical {...props} />
      {props.page()}
    </div>
  );
}
