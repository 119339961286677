import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// App Components
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import webRoutes from "../webRoutes";
import RolesGuard from '../RolesGuard';
// react-bootstrap-table2
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import cellEditFactory from "react-bootstrap-table2-editor";
// Other
import Alert from "react-s-alert";

export default function ProjectTable(props) {
  const { t, i18n } = useTranslation();
  const api = props.apiRoute;

  const [records, setRecords] = useState();

  useEffect(() => {
    
    FetchService.simpleFetch(apiRoutes.PROJECT.LIST, setRecords);
  }, [props.modelUpdatesCounter]);

  const handleDelete = async (event) => {
    const id = event.target.getAttribute("data-id");

    if (window.confirm(t("messages::Are you sure do you want to delete it?"))) {
      FetchService.simpleDelete(apiRoutes.PROJECT.DELETE + "/" + id, () => {
        // Show in app a user friendly message.
        Alert.success(t("messages::Item has been removed"));
        FetchService.simpleFetch(apiRoutes.PROJECT.LIST, setRecords);
      });
    }
  };

  const handleEdit = (event) => {
    const id = event.target.getAttribute("data-id");
    props.setIdToEdit(id);
    props.handleOpenEditModal();
  };

  const columns = [
    /*
    {
      dataField: 'id',
      text: t('model::fields::id'),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::id'),
          })
        : false,
    },
    */
    {
      dataField: "name",
      text: t("model::fields::name"),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder: t("actions::Enter") + " " + t("model::fields::name"),
          })
        : null,
    },
    {
      dataField: "observation",
      text: t("model::fields::observation"),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder:
              t("actions::Enter") + " " + t("model::fields::observation"),
          })
        : null,
    },
    {
      dataField: "state",
      text: t("model::fields::state"),
      sort: true,
      formatter: (cell, row, rowIndex, colIndex) => {
        
        return cell
        ? t("selectOptions::project::state::" + cell)
        : "";
      },
      filter: props.filter
        ? textFilter({
            placeholder:
              t("actions::Enter") + " " + t("model::fields::state"),
          })
        : null,
    },
    {
      dataField: "type",
      text: t("model::fields::type"),
      sort: true,
      formatter: (cell, row, rowIndex, colIndex) => {
        
        return cell
        ? t("selectOptions::project::types::" + cell)
        : "";
      },
      filter: props.filter
        ? textFilter({
            placeholder:
              t("actions::Enter") + " " + t("model::fields::types"),
          })
        : null,
    },
    {
      dataField: "priority",
      text: t("model::fields::priority"),
      sort: true,
      hidden: !RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]),
      formatter: (cell, row, rowIndex, colIndex) => {
        
        return cell
        ? t("selectOptions::project::priority::" + cell)
        : "";
      },
      filter: props.filter
        ? textFilter({
            placeholder:
              t("actions::Enter") + " " + t("model::fields::priority"),
          })
        : null,
    },
    {
      dataField: "center.name",
      text: t("model::fields::center"),
      sort: true,
      style: {
        whiteSpace: 'normal'
      },
      filter: props.filter
        ? textFilter({
            placeholder:
              t("actions::Enter") + " " + t("model::fields::center"),
          })
        : null,
    },
    {
      dataField: "informer.username",
      text: t("model::fields::informer"),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder:
              t("actions::Enter") + " " + t("model::fields::informer"),
          })
        : null,
    },
    {
      dataField: "id",
      text: t("actions::Actions"),
      sort: false,
      formatter: actionsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "30em" };
      },
    },
  ];

  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp; {from} - {to} &nbsp;
      <small className="text-muted">[Total: {size}]</small>
    </span>
  );

  const pagination = paginationFactory({
    sizePerPage: 10,
    showTotal: true,
    paginationTotalRenderer: paginationTotalRenderer,
  });

  const cellEdit = cellEditFactory({
    mode: "dbclick",
    beforeSaveCell: (oldValue, newValue, row, column, done) => {
      setTimeout(() => {
        if (window.confirm(t("messages::Do you want to accep this change?"))) {
          done(); // contine to save the changes
          FetchService.simplePut(
            api.EDIT + "/" + row.id,
            {
              [column.dataField]: newValue,
            },
            () => {
              Alert.success(t("messages::Item has been edited"));
            }
          );
        } else {
          done(false); // reject the changes
        }
      }, 0);
      return { async: true };
    },
    afterSaveCell: (oldValue, newValue, row, column) => {},
  });

  function actionsFormatter(cell, row, rowIndex, formatExtraData) {
    
    return (
      <div className="text-right">
        <React.Fragment>
          {!props.hideDetailLink ? (
            <a
              type="button"
              data-id={cell}
              className="btn btn-outline-primary btn-sm"
              href={webRoutes.PROJECT.DETAIL + "/" + cell}
            >
              <i className="fas fa-eye"></i>&nbsp;
              {t("actions::Detail")}
            </a>
          ) : null}

          {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) || row.id_informer==props.idUser ? (
            <button
              type="button"
              data-id={cell}
              className="btn btn-outline-primary btn-sm"
             
              onClick={handleEdit}
            >
              <i className="fas fa-pen-alt"></i>&nbsp;
              {t("actions::Edit")}
            </button>
          ) : (
            ""
          )}
          {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL"]) ? (
            <button
              type="button"
              data-id={cell}
              className="btn btn-outline-danger btn-sm"
              onClick={handleDelete}
            >
              <i className="fas fa-times-circle"></i>&nbsp;
              {t("actions::Delete")}
            </button>
          ) : (
            ""
          )}
        </React.Fragment>
      </div>
    );
  }

  return (
    <React.Fragment>
      {records && records.length ? (
        <div className="table-responsive" style={{ marginBottom: "50px" }}>
          <BootstrapTable
            keyField="id"
            data={records}
            columns={columns}
            caption={props.caption}
            bootstrap4={true}
            headerWrapperClasses="thead-light"
            bordered={false}
            hover
            condensed
            filter={filterFactory()}
            pagination={pagination}
            cellEdit={cellEdit}
          />
        </div>
      ) : (
        <div className="text-center">
          <div className="alert alert-secondary" role="alert">
            {records ? (
              <strong>{t("literals::No data")}</strong>
            ) : (
              <div className="text-center">
                <img
                  src="../spinner.gif"
                  width="100"
                  alt=""
                  style={{ opacity: "0.5" }}
                />
                <div className="text-muted">
                  <i>{t("literals::Loading")}</i>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
