import React from 'react';
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
// App Components
import Footer from '../components/Footer';
import apiRoutes from '../apiRoutes';
import webRoutes from '../webRoutes';
import CrudService from './CrudService';

export default function Services(props) {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(
            <div>
              <a className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block" href="/">
                {t('menu::Dashboard')} /
              </a>

              <span className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block" href="#">
                {t(props.title)}
              </span>
            </div>
          )}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7 fondoAdmin">
            <CrudService
              pageName={'pages::Services'}
              entityName={'model::entities::service'}
              apiRoute={apiRoutes.SERVICE}
              webRoute={webRoutes.SERVICE}
              hideDetailLink={true}
            />
            {/* <!-- Footer --> */}
            
          </div>
          <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}

function ServicesContainer() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header bg-transparent">
              <h1 className="mb-0">{t('pages::Services')}</h1>
            </div>
            <div className="card-body">
              <p>
                Praesent ut tristique arcu. Quisque sagittis egestas tellus, ac molestie leo faucibus sit amet.
                Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent
                condimentum in eros vitae vestibulum. Curabitur vulputate ex justo. Nulla facilisi. Maecenas a magna non
                orci malesuada sagittis. Sed eleifend sem nibh, at euismod lacus congue vel. Nullam ornare maximus
                eleifend. Integer luctus imperdiet sagittis. Fusce mattis tempus ex, sed fermentum mi consequat vitae.
                Morbi fringilla fringilla leo non venenatis. Nullam in elit nec augue vestibulum pellentesque.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
