import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import Alert from "react-s-alert";

//import Select from "react-select";
import { Select, Dropdown } from "semantic-ui-react";

export default function UsersNew(props) {
  const { t } = useTranslation();
  const [form, setState] = useState({
    selectedRole: "",
    newUsername: "",
    newPassword: "",
    repeatPassword: "",
    email: "",
    name: "",
    surname: "",
    centers: [],
  });
  const [roles, setRoles] = useState([]);
  const [centers, setCenters] = useState([]);

  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.USER.ROLES, setRoles);
  }, []);

  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.CENTER.LIST, setCenters);
  }, []);

  let rolesList = roles.map((role, index) => {
    const container = {};
    container["key"] = role.id;
    container["value"] = role.id;
    container["text"] = role.name;

    return container;
  });

  let centersList = centers.map((center, index) => {
    const container = {};
    container["key"] = center.id;
    container["value"] = center.id;
    container["text"] = center.name;

    return container;
  });

  let handleSubmit = async (event) => {
    event.preventDefault();
  
    if (
      form.newUsername != null &&
      form.newPassword == form.repeatPassword &&
      form.selectedRole != undefined
    ) {
      FetchService.simplePost(
        apiRoutes.USER.NEW,
        {
          username: form.email,
          password: form.newPassword,
          name: form.name,
          surname: form.surname,
          idRol: form.selectedRole,
          idCenters: form.centers,
          email: form.email,
        },
        (data) => {
          Alert.success("Usuario creado con exito");
          setState({
            ...form,
            selectedRole: "",
            newUsername: "",
            newPassword: "",
            repeatPassword: "",
            email: "",
            name: "",
            surname: "",
            centers: [],
          });
          props.onChange();
        }
      );
    } else {
      console.log("error");
    }
  };

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  let handleChangeRole = (event, e) => {
   
    setState({
      ...form,
      selectedRole: e.value,
    });
  };

  let handleChangeCenters = (event, e) => {
    
    setState({
      ...form,
      centers: e.value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <div className="form-group">
        <label for="newUsername"> {t("model::fields::name")}</label>
        <input
          id="newUsername"
          className="form-control"
          type="text"
          value={form.newUsername}
          onChange={updateField}
          name="newUsername"
          required
        ></input>
      </div> */}
       <div className="form-group">
        <label for="email"> {t("model::fields::email")}</label>
        <input
          id="email"
          className="form-control"
          type="email"
          value={form.email}
          onChange={updateField}
          name="email"
          required
        ></input>
      </div>
      <div className="form-group">
        <label for="newPassword"> {t("model::fields::password")}</label>
        <input
          id="newPassword"
          className="form-control"
          type="password"
          value={form.newPassword}
          onChange={updateField}
          name="newPassword"
          required
          autocomplete="new-password"
        ></input>
      </div>
      <div className="form-group">
        <label for="repeatPassword">
          {" "}
          {t("model::fields::repeatPassword")}
        </label>
        <input
          id="repeatPassword"
          className="form-control"
          type="password"
          value={form.repeatPassword}
          onChange={updateField}
          name="repeatPassword"
          required
        ></input>
      </div>
      <div className="form-group">
        <label for="name"> {t("model::fields::name")}</label>
        <input
          id="name"
          className="form-control"
          type="text"
          value={form.name}
          onChange={updateField}
          name="name"
          required
        ></input>
      </div>
      <div className="form-group">
        <label for="surname"> {t("model::fields::surname")}</label>
        <input
          id="surname"
          className="form-control"
          type="text"
          value={form.surname}
          onChange={updateField}
          name="surname"
          required
        ></input>
      </div>
     

      <div className="form-group">
        <label for="role"> {t("model::lists::roles")}</label>
        <Dropdown
          placeholder={t("placeholders::selectRole")}
          onChange={handleChangeRole}
          value={form.selectedRole}
          className="form-control"
          fluid
          multiple
          selection
          options={rolesList}
        />
      </div>
      <div className="form-group">
        <label for="center"> {t("model::fields::centers")}</label>
        <Dropdown
          placeholder={t("placeholders::selectCenters")}
          onChange={handleChangeCenters}
          value={form.centers}
          className="form-control"
          fluid
          multiple
          selection
          options={centersList}
        />
      </div>

      <br />

      <button className="btn btn-primary">{t("actions::New")}</button>
    </form>
  );
}
