const webRoutes = {
  PROJECT: {
    DETAIL: '/projects/detail',
  },
  ISSUE: {
    DETAIL: '/issues/detail',
  },
  SERVICE: {
    DETAIL: '/services/detail',
  },
  CENTER: {
    DETAIL: '/centers/detail',
  },
};

export default webRoutes;
