import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import $ from "jquery";
// App Components
import Footer from '../components/Footer';
import FetchService from '../FetchService';
import RolesGuard from '../RolesGuard';
import apiRoutes from '../apiRoutes';
import webRoutes from '../webRoutes';
import AuthService from '../AuthService';
import NewIssue from './IssueNew';
import EditIssue from './IssueEdit';

// react-bootstrap-table2
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
// Other
import Alert from 'react-s-alert';

export default function IssuesAssistant(props) {
  const { t, i18n } = useTranslation();

  const [filter, setFilter] = useState(false);
  


  
  
    
   
  const handleFilter = (event) => {
    setFilter(!filter);
  };

  

  return (
    <React.Fragment>
    {props.account ? (
      <div className="main-content">
        {/* <!-- Navbar --> */}
        {props.setNavbarTop(
          <div>
            <a
              className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
              href="/"
            >
              {t("menu::Dashboard")} /
            </a>

            <span
              className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block"
              href="#"
            >
              {t(props.title)}
            </span>
          </div>
        )}
        {/* <!-- End Navbar --> */}
        {/* <!-- Header --> */}
        <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
        <div className="container-fluid mt--7 fondo">
        <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="mb-0">{t('pages::Issues')}</h3>
                </div>
                <div className="col text-right">
                  <button type="button" className="btn btn-outline-primary" onClick={handleFilter}>
                    {filter ? <i className="fas fa-toggle-on"></i> : <i className="fas fa-toggle-off"></i>}
                    &nbsp;&nbsp;{t('actions::Filter')}
                  </button>
                  &nbsp;&nbsp;
                  
                </div>
              </div>
            </div>
            <div className="card-body">
              <IssuesTable
                
               
                filter={filter}
                
                entityName={props.entityName}
               
              />
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
          {/* <CrudNameObservation
            pageName={'pages::Projects'}
            entityName={'model::entities::project'}
            apiRoute={apiRoutes.PROJECT}
            webRoute={webRoutes.PROJECT}
          /> */}
         
        </div>
         {/* <!-- Footer --> */}
         <Footer />
      </div>
    ) : (
      <Redirect to="/" />
    )}
  </React.Fragment>
    
  );
}

function IssuesTable(props) {
  const { t, i18n } = useTranslation();
  const [records, setRecords] = useState();

  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.ISSUE.BYASSISTANT + '/' + props.idUser, setRecords);
  }, []);

 

  const columns = [
    {
      dataField: 'name',
      text: t('model::fields::name'),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::name'),
          })
        : null,
    },
    {
      dataField: 'project.name',
      text: t('model::fields::project'),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::project'),
          })
        : null,
    },
    {
      dataField: 'observation',
      text: t('model::fields::observation'),
      sort: true,
      filter: props.filter
        ? textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::observation'),
          })
        : null,
    },
    {
      dataField: 'state',
      text: t('model::fields::state'),
      sort: true,
      formatter: stateFormatter,
      filter: props.filter
        ? textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::state'),
          })
        : null,
    },
    {
      dataField: 'id',
      text: t('actions::Actions'),
      sort: false,
      formatter: actionsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '30em' };
      },
    },
  ];

  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp; {from} - {to} &nbsp;<small className="text-muted">[Total: {size}]</small>
    </span>
  );

  const pagination = paginationFactory({
    sizePerPage: 10,
    showTotal: true,
    paginationTotalRenderer: paginationTotalRenderer,
  });

  
  function stateFormatter(cell, row, rowIndex, formatExtraData) {
    if(cell){
    return(t("selectOptions::issue::state::"+cell))
    }
  }

  function actionsFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="text-right">
        <React.Fragment>
          <a
            type="button"
            data-id={cell}
            className="btn btn-outline-primary btn-sm"
            href={webRoutes.ISSUE.DETAIL + '/' + cell}
          >
            <i className="fas fa-eye"></i>&nbsp;
            {t('actions::Detail')}
          </a>
         
        </React.Fragment>
      </div>
    );
  }

  return (
    <React.Fragment>

 
      {records && records.length ? (
        <div className="table-responsive" style={{ marginBottom: '50px' }}>
          <BootstrapTable
            keyField="id"
            data={records}
            columns={columns}
            caption={props.caption}
            bootstrap4={true}
            headerWrapperClasses="thead-light"
            bordered={false}
            hover
            condensed
            filter={filterFactory()}
            pagination={pagination}
            
          />
        </div>
      ) : (
        <div className="text-center">
          <div className="alert alert-secondary" role="alert">
            {records ? (
              <strong>{t('literals::No data')}</strong>
            ) : (
              <div className="text-center">
                <img src="../spinner.gif" width="100" alt="" style={{ opacity: '0.5' }} />
                <div className="text-muted">
                  <i>{t('literals::Loading')}</i>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
