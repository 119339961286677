import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import RolesGuard from "../RolesGuard";
import $ from "jquery";
// App Components
import Footer from "../components/Footer";
import ProjectTable from "./ProjectTable";
import NewProjectModal from "./NewProjectModal";
import EditProjectModal from "./EditProjectModal";
import RequestCenterModal from "../centers/RequestCenterModal";
import SimpleEntityTable from "../components/SimpleEntityTable";
import apiRoutes from "../apiRoutes";
import webRoutes from "../webRoutes";
import CrudNameObservation from "../crud-name-observation/CrudNameObservation";

export default function Projects(props) {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(
            <div>
              <a
                className="h4 mb-0 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="/"
              >
                {t("menu::Dashboard")} /
              </a>

              <span
                className="h2 mb-0 ml-2 text-sivcom text-uppercase d-none d-lg-inline-block"
                href="#"
              >
                {t(props.title)}
              </span>
            </div>
          )}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7 fondo">
            <ProjectsContainer {...props} />
            {/* <CrudNameObservation
              pageName={'pages::Projects'}
              entityName={'model::entities::project'}
              apiRoute={apiRoutes.PROJECT}
              webRoute={webRoutes.PROJECT}
            /> */}
            {/* <!-- Footer --> */}
          </div>
          <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}

function ProjectsContainer(props) {
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState(false);
  const [idToEdit, setIdToEdit] = useState();
  const [modelUpdatesCounter, setModelUpdatesCounter] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showRequestCenter, setShowRequestCenter] = useState(false);

  const handleFilter = (event) => {
    setFilter(!filter);
  };

  const handleModelUpdate = () => {
    setModelUpdatesCounter(modelUpdatesCounter + 1);
    setShowEdit(false);
    setShowNew(false);
    // $("#new-modal").hide();
    // $("#edit-modal").hide();
    // $(".modal-backdrop").remove();
  };
  const handleCloseEditModal = () => setShowEdit(false);
  const handleOpenEditModal = (event) => {
    setShowEdit(true);
  };
  const handleOpenNewModal = () => setShowNew(true);
  const handleCloseNewModal = () => setShowNew(false);
  const handleOpenRequestCenterModal = () => {
    setShowRequestCenter(true);
  }
  const handleCloseRequestCenterModal = () => setShowRequestCenter(false);
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="mb-0">{t("pages::Projects")}</h3>
                </div>
                <div className="col text-right">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={handleFilter}
                  >
                    {filter ? (
                      <i className="fas fa-toggle-on"></i>
                    ) : (
                      <i className="fas fa-toggle-off"></i>
                    )}
                    &nbsp;&nbsp;{t("actions::Filter")}
                  </button>
                  &nbsp;&nbsp;
                  {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL", "EXTERNAL"]) ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={handleOpenNewModal}
                    >
                      <i className="fas fa-plus"></i>&nbsp;&nbsp;
                      {t("actions::New")}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="card-body">
              <ProjectTable
                handleOpenEditModal={handleOpenEditModal}
                modelUpdatesCounter={modelUpdatesCounter}
                handleModelUpdate={handleModelUpdate}
                setIdToEdit={setIdToEdit}
                filter={filter}
                hideDetailLink={props.hideDetailLink}
                {...props}
              />
            </div>
            {RolesGuard.hasAnyRole(["ADMIN", "INTERNAL", "EXTERNAL"]) ? (
              <div>
                <NewProjectModal
                  showNew={showNew}
                  handleModelUpdate={handleModelUpdate}
                  handleCloseNewModal={handleCloseNewModal}
                  handleOpenRequestCenterModal={handleOpenRequestCenterModal}
                  {...props}
                />
                <RequestCenterModal
                  apiRoute={props.apiRoute}
                  entityName={props.entityName}
                  idUser={props.idUser}
                  handleCloseRequestCenterModal={handleCloseRequestCenterModal}
                  showRequestCenter={showRequestCenter}
                />
                <EditProjectModal
                  showEdit={showEdit}
                  handleModelUpdate={handleModelUpdate}
                  idToEdit={idToEdit}
                  handleCloseEditModal={handleCloseEditModal}
                  showRequestCenter={showRequestCenter}
                  {...props}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
