import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// App Components
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import Alert from "react-s-alert";
// react-bootstrap-table2
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Modal } from "react-bootstrap";

export default function NewCollaborationModal(props) {
  const { t, i18n } = useTranslation();

  
  const [collaboration, setCollaboration] = useState([]);
  const [records, setRecords] = useState([]);
  

  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.USER.EXTERNALUSERS, setRecords);
  }, [props.modelUpdatesCounter]);
  useEffect(() => {
    FetchService.simpleFetch(
      apiRoutes.COLLABORATION.LIST + "/" + props.projectId,
      setCollaboration
    );
  }, [props.modelUpdatesCounter]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const id = event.target.getAttribute("data-id");
    
    const body = {
      idUser: id,
      idProject: props.projectId,
    };

    FetchService.simplePost(apiRoutes.COLLABORATION.NEW, body, () => {
      Alert.success(t("messages::Item has been added"));
      props.handleModelUpdate();
      
    });
  };
  const columns = [
    {
      dataField: "username",
      text: t("model::fields::name"),
      sort: true,
      filter:
        textFilter({
            placeholder: t("actions::Enter") + " " + t("model::fields::name"),
          })
        ,
    },

    {
      dataField: "id",
      text: t("actions::Actions"),
      sort: false,
      formatter: actionsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "20em" };
      },
    },
  ];
  function actionsFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="text-right">
       
        <React.Fragment>
          <button
            type="button"
            data-id={cell}
            className="btn btn-outline-danger btn-sm"
            onClick={handleSubmit}
          >
            {t("actions::Add")}
          </button>
        </React.Fragment>
      </div>
    );
  }
  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp; {from} - {to} &nbsp;
      <small className="text-muted">[Total: {size}]</small>
    </span>
  );

  let collaborationList = [];
  if (records.users) {
   
    records.users.map((externalUser) => {
      if (collaboration.filter((d) => d.idUser == externalUser.id).length == 0) {
        collaborationList.push(externalUser);
      }
    });
  }
  const pagination = paginationFactory({
    sizePerPage: 10,
    showTotal: true,
    paginationTotalRenderer: paginationTotalRenderer,
  });
  return (
    <React.Fragment>
      <Modal show={props.showNew} onHide={props.handleCloseNewModal}>
       
      
      <Modal.Header closeButton>
      <Modal.Title>
                {t("actions::Add") + " " + t(props.entityName)}
                </Modal.Title>
             
              </Modal.Header>
              <Modal.Body>
              <BootstrapTable
                keyField="id"
                data={collaborationList}
                columns={columns}
                caption={props.caption}
                bootstrap4={true}
                headerWrapperClasses="thead-light"
                bordered={false}
                hover
                condensed
                filter={filterFactory()}
                pagination={pagination}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={props.handleCloseNewModal}
              >
                {t("actions::Close")}
              </button>
            </Modal.Footer>
         
      </Modal>
    </React.Fragment>
  );
}
