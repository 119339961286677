import AuthService from './AuthService';

export const Roles = Object.freeze({
  ADMIN: 'ADMIN',
  USER: 'EXTERNAL',
  VIEWER: 'INTERNAL',
});

class RolesGuard {
  isAdmin = () => {
    const decodedUserToken = AuthService.getDecodedUserToken();
    if (!decodedUserToken || !decodedUserToken.roles) return false;

    const found = decodedUserToken.roles.find((role) => role.name === 'ADMIN');
    return found ? true : false;
  };

  hasRole = (roleName) => {
    // Generic function to check a role passed by param

    const decodedUserToken = AuthService.getDecodedUserToken();
    if (!decodedUserToken || !decodedUserToken.roles) return false;
    if (typeof roleName !== 'string') return false;

    const found = decodedUserToken.roles.find((role) => role.name === roleName);
    return found ? true : false;
  };

  hasAnyRole = (roleNames) => {
    // Generic function to check an array of roles passed by param

    const decodedUserToken = AuthService.getDecodedUserToken();
    if (!decodedUserToken || !decodedUserToken.roles) return false;
    if (!Array.isArray(roleNames)) return false;

    const userHasAnyRole = decodedUserToken.roles.find((userRole) => {
      const foundUserRoleInArray = roleNames.find((roleName) => {
        return roleName === userRole.name;
      });
      if (foundUserRoleInArray) return true;
      else return false;
    });

    return userHasAnyRole ? true : false;
  };
}

export default new RolesGuard();
