import React, { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './components/LanguageSelector';
import AuthService from './AuthService';
import Alert from 'react-s-alert';
import jwtDecode from 'jwt-decode';
import Footer from './components/Footer';

export default function Login(props) {
  const { t, i18n } = useTranslation();
  // State
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    AuthService.logOut();
  }, []);

  let handleSubmit = async (event) => {
    event.preventDefault();
    const credentials = { username: username, password: password };
    try {
      const response = await AuthService.login(credentials);
      
      if (response.status === 200) {
        AuthService.setUserInfo(response.data);
        setLogged(true);
        props.setLoggedAccount(username);
      }
    } catch (error) {
      Alert.error('<p><strong>' + error.response.statusText + '</strong>:<br/>' + error.response.data.message + '</p>');
      setUsername('');
      setPassword('');
      setMessage({ message: error.response.data.message });
    }
    /*
    if (username == 'admin' && password == 'admin') {
      setLogged(true);
      props.setLoggedAccount(username);
    } else {
      alert('There is not any username with that password.');
    }
    */
  };

  let handleUsername = (event) => {
    setUsername(event.target.value);
  };

  let handlePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <React.Fragment>
      {logged ? (
        <Redirect to="/" />
      ) : (
        <div className="main-content bg-default fondoLogin">
          {/*<!-- Navbar -->*/}
          <nav className="navbar navbar-top navbar-horizontal navbar-expand-md ">
            <div className="container px-4">
              <Link
                className="navbar-brand"
                to="/"
                style={{ padding: '4px 10px 0 20px', marginRight: '0', backgroundColor: 'white', borderRadius: '7px' }}
              >
                <img src="../assets/img/brand/logo.png" alt="" style={{ height: '40px' }} />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-collapse-main"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbar-collapse-main">
                {/*<!-- Collapse header -->*/}
                <div className="navbar-collapse-header d-md-none">
                  <div className="row">
                    <div className="col-6 collapse-brand">
                      <Link to="/">
                        <img src="../assets/img/brand/logo.png" alt="" />
                      </Link>
                    </div>
                    <div className="col-6 collapse-close">
                      <button
                        type="button"
                        className="navbar-toggler"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-main"
                        aria-controls="sidenav-main"
                        aria-expanded="false"
                        aria-label="Toggle sidenav"
                      >
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* <!-- Language --> */}
                <LanguageSelector />

                {/*<!-- Navbar items -->*/}
                {/* <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="ni ni-circle-08"></i>
                      <span className="nav-link-inner--text">{t('login::Register')}</span>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </nav>
          {/*<!-- Header -->*/}
          <div className="header  py-6 py-lg-7">
            <div className="container">
              <div className="header-body text-center mb-7">
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-6">
                    <h1 >{t('login::Welcome')}</h1>
                    <p className="text-lead ">{t('login::Use this form to login')}.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                x="0"
                y="0"
                viewBox="0 0 2560 100"
                preserveAspectRatio="none"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div> */}
          </div>
          {/*<!-- Page content -->*/}
          <div className="container mt--8 pb-5">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-7">
                <div className="card bg-secondary shadow border-0">
                  <div className="card-body px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{t('login::Sign in with username and password')}</small>
                    </div>
                    <form role="form" onSubmit={handleSubmit}>
                      <div className="form-group mb-3">
                        <div className="input-group input-group-alternative">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="ni ni-single-02"></i>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            placeholder={t('login::Username')}
                            type="text"
                            required="required"
                            value={username}
                            onChange={handleUsername}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-alternative">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="ni ni-lock-circle-open"></i>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            placeholder={t('login::Password')}
                            type="password"
                            required="required"
                            value={password}
                            onChange={handlePassword}
                          />
                        </div>
                      </div>
                      {/*
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input className='custom-control-input' id=' customCheckLogin' type='checkbox' />
                        <label className='custom-control-label' htmlFor=' customCheckLogin'>
                          <span className='text-muted'>{t('login::Remember me')}</span>
                        </label>
                      </div>
                      */}
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary my-4">
                          {t('login::Sign in')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row mt-3">
                  {/*
                  <div className="col-6">
                    <a href="#" className="text-light">
                      <small>{t('login::Forgot password?')}</small>
                    </a>
                  </div>
                  <div className="col-6 text-right">
                    <a href="#" className="text-light">
                      <small>{t('login::Create new account')}</small>
                    </a>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
          {/* <footer className="py-5">
            <div className="container">
              <div className="row align-items-center justify-content-xl-between">
                <div className="col-xl-6">
                  <div className="copyright text-center text-xl-left text-muted">
                    © 2021{' '}
                    <a
                      href="https://www.fundacionctic.org/"
                      className="font-weight-bold ml-1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CTIC
                    </a>
                  </div>
                </div>
                <div className="col-xl-6">
                  <ul className="nav nav-footer justify-content-center justify-content-xl-end">
                    <li className="nav-item">
                      <a
                        href="https://www.fundacionctic.org/"
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CTIC - {t('footer::Technological Center')}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://www.fundacionctic.org/es/sobre-ctic"
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('footer::About Us')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer> */}
        </div>
      )}
    </React.Fragment>
  );
}
