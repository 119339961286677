import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// App Components
import Alert from 'react-s-alert';
import QrReaderComponent from 'react-qr-reader';
import Footer from '../components/Footer';

export default function QrReader(props) {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      {props.account ? (
        <div className="main-content">
          {/* <!-- Navbar --> */}
          {props.setNavbarTop(t(props.title))}
          {/* <!-- End Navbar --> */}
          {/* <!-- Header --> */}
          <div className="header bg-gradient-primary pb-6 pt-5 pt-md-8"></div>
          <div className="container-fluid mt--7">
            <QrReaderContainer />
            {/* <!-- Footer --> */}
            <Footer />
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}

function QrReaderContainer() {
  const { t, i18n } = useTranslation();
  const [qrCode, setQrCode] = useState([]);

  // https://stackoverflow.com/questions/14636536/how-to-check-if-a-variable-is-an-integer-in-javascript
  function isInt(value) {
    return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
  }

  let handleScan = (data) => {
    if (data) {
      //const qrCode = parseInt(data, 10);
      const qrCode = data;
      
      setQrCode(qrCode);
    }
  };

  let handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            {/*
            <div className='card-header bg-transparent'>
              <h1 className='mb-0'>{t('pages::QR Reader')}</h1>
            </div>
            */}
            <div className="card-body text-center">
              <div style={{ width: '100%', maxWidth: '400px', margin: 'auto' }}>
                <QrReaderComponent delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
                <p
                  className="lead"
                  style={{ marginTop: '20px', fontSize: '1.2rem', lineHeight: '1.5rem', minHeight: '1.5rem' }}
                >
                  {<strong>{qrCode ? qrCode : null}</strong>}
                </p>
                <div>
                  {isInt(qrCode) ? (
                    <div>
                      <Link className="btn btn-icon btn-2 btn-primary" type="button" to={'/qr-' + qrCode}>
                        {t('actions::Detail')}
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <button className="btn btn-icon btn-2 bg-light" type="button" disabled>
                        {t('actions::Detail')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
