import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// App Components
import FetchService from "../FetchService";
import apiRoutes from "../apiRoutes";
import Alert from "react-s-alert";

export default function NewCenterModal(props) {
  const { t, i18n } = useTranslation();

  const [form, setState] = useState({
    name: "",
    CCN: "",
    CODCNH: "",
    typeRoad: "",
    address: "",
    addressNumber: "",
    cityCode: "",
    city: "",
    provinceCode: "",
    provinceName: "",
    CCAACode: "",
    CCAAName: "",
    country: "",
    postalCode: "",
    classCode: "",
    classCenter: "",
    funcionalDependenceCode: "",
    funcionalDependenceName: "",
    complexCode: "",
    complexName: "",
    phone: "",
    email: "",
    observation: "",
  });
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      name: form.name,
      CCN: form.CCN,
      CODCNH: form.CODCNH,
      typeRoad: form.typeRoad,
      address: form.address,
      addressNumber: form.addressNumber,
      cityCode: form.cityCode,
      city: form.city,
      provinceCode: form.provinceCode,
      provinceName: form.provinceName,
      CCAACode: form.CCAACode,
      CCAAName: form.CCAAName,
      country: form.country,
      postalCode: form.postalCode,
      classCode: form.classCode,
      classCenter: form.classCenter,
      funcionalDependenceCode: form.funcionalDependenceCode,
      funcionalDependenceName: form.funcionalDependenceName,
      complexCode: form.complexCode,
      complexName: form.complexName,
      phone: form.phone,
      email: form.email,
      observation: form.observation,
      idProject: props.projectId,
    };

    FetchService.simplePost(props.apiRoute.NEW, body, () => {
      Alert.success(t("messages::Item has been added"));
      props.handleModelUpdate();
    });
  };

  return (
    <React.Fragment>
      <div
        className="modal fade"
        id="new-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="new-modal-label"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="new-modal-label">
                  {t("actions::Add") + " " + t(props.entityName)}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-name"
                    >
                      {t("model::fields::name")}
                    </label>
                    <input
                      type="text"
                      id="input-new-name"
                      className="form-control"
                      placeholder={t("model::fields::name")}
                      value={form.name}
                      onChange={updateField}
                      name="name"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-CCN"
                    >
                      {t("model::fields::CCN")}
                    </label>
                    <input
                      type="text"
                      id="input-new-CCN"
                      className="form-control"
                      placeholder={t("model::fields::CCN")}
                      value={form.CCN}
                      onChange={updateField}
                      name="CCN"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-CODCNH"
                    >
                      {t("model::fields::CODCNH")}
                    </label>
                    <input
                      type="text"
                      id="input-new-CODCNH"
                      className="form-control"
                      placeholder={t("model::fields::CODCNH")}
                      value={form.CODCNH}
                      onChange={updateField}
                      name="CODCNH"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-typeRoad"
                    >
                      {t("model::fields::typeRoad")}
                    </label>
                    <input
                      type="text"
                      id="input-new-typeRoad"
                      className="form-control"
                      placeholder={t("model::fields::typeRoad")}
                      value={form.typeRoad}
                      onChange={updateField}
                      name="typeRoad"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-address"
                    >
                      {t("model::fields::address")}
                    </label>
                    <input
                      type="text"
                      id="input-new-address"
                      className="form-control"
                      placeholder={t("model::fields::address")}
                      value={form.address}
                      onChange={updateField}
                      name="address"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-addressNumber"
                    >
                      {t("model::fields::addressNumber")}
                    </label>
                    <input
                      type="text"
                      id="input-new-addressNumber"
                      className="form-control"
                      placeholder={t("model::fields::addressNumber")}
                      value={form.addressNumber}
                      onChange={updateField}
                      name="addressNumber"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-cityCode"
                    >
                      {t("model::fields::cityCode")}
                    </label>
                    <input
                      type="text"
                      id="input-new-cityCode"
                      className="form-control"
                      placeholder={t("model::fields::cityCode")}
                      value={form.cityCode}
                      onChange={updateField}
                      name="cityCode"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-city"
                    >
                      {t("model::fields::city")}
                    </label>
                    <input
                      type="text"
                      id="input-new-city"
                      className="form-control"
                      placeholder={t("model::fields::city")}
                      value={form.city}
                      onChange={updateField}
                      name="city"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-provinceCode"
                    >
                      {t("model::fields::provinceCode")}
                    </label>
                    <input
                      type="text"
                      id="input-new-provinceCode"
                      className="form-control"
                      placeholder={t("model::fields::provinceCode")}
                      value={form.provinceCode}
                      onChange={updateField}
                      name="provinceCode"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-provinceName"
                    >
                      {t("model::fields::provinceName")}
                    </label>
                    <input
                      type="text"
                      id="input-new-provinceName"
                      className="form-control"
                      placeholder={t("model::fields::provinceName")}
                      value={form.provinceName}
                      onChange={updateField}
                      name="provinceName"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-CCAACode"
                    >
                      {t("model::fields::CCAACode")}
                    </label>
                    <input
                      type="text"
                      id="input-new-CCAACode"
                      className="form-control"
                      placeholder={t("model::fields::CCAACode")}
                      value={form.CCAACode}
                      onChange={updateField}
                      name="CCAACode"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-CCAAName"
                    >
                      {t("model::fields::CCAAName")}
                    </label>
                    <input
                      type="text"
                      id="input-new-CCAAName"
                      className="form-control"
                      placeholder={t("model::fields::CCAAName")}
                      value={form.CCAAName}
                      onChange={updateField}
                      name="CCAAName"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-country"
                    >
                      {t("model::fields::country")}
                    </label>
                    <input
                      type="text"
                      id="input-new-country"
                      className="form-control"
                      placeholder={t("model::fields::country")}
                      value={form.country}
                      onChange={updateField}
                      name="country"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-postalCode"
                    >
                      {t("model::fields::postalCode")}
                    </label>
                    <input
                      type="text"
                      id="input-new-postalCode"
                      className="form-control"
                      placeholder={t("model::fields::postalCode")}
                      value={form.postalCode}
                      onChange={updateField}
                      name="postalCode"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-classCode"
                    >
                      {t("model::fields::classCode")}
                    </label>
                    <input
                      type="text"
                      id="input-new-classCode"
                      className="form-control"
                      placeholder={t("model::fields::classCode")}
                      value={form.classCode}
                      onChange={updateField}
                      name="classCode"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-classCenter"
                    >
                      {t("model::fields::classCenter")}
                    </label>
                    <input
                      type="text"
                      id="input-new-classCenter"
                      className="form-control"
                      placeholder={t("model::fields::classCenter")}
                      value={form.classCenter}
                      onChange={updateField}
                      name="classCenter"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-funcionalDependenceCode"
                    >
                      {t("model::fields::funcionalDependenceCode")}
                    </label>
                    <input
                      type="text"
                      id="input-new-funcionalDependenceCode"
                      className="form-control"
                      placeholder={t("model::fields::funcionalDependenceCode")}
                      value={form.funcionalDependenceCode}
                      onChange={updateField}
                      name="funcionalDependenceCode"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-funcionalDependenceName"
                    >
                      {t("model::fields::funcionalDependenceName")}
                    </label>
                    <input
                      type="text"
                      id="input-new-funcionalDependenceName"
                      className="form-control"
                      placeholder={t("model::fields::funcionalDependenceName")}
                      value={form.funcionalDependenceName}
                      onChange={updateField}
                      name="funcionalDependenceName"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-complexCode"
                    >
                      {t("model::fields::complexCode")}
                    </label>
                    <input
                      type="text"
                      id="input-new-complexCode"
                      className="form-control"
                      placeholder={t("model::fields::complexCode")}
                      value={form.complexCode}
                      onChange={updateField}
                      name="complexCode"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-complexName"
                    >
                      {t("model::fields::complexName")}
                    </label>
                    <input
                      type="text"
                      id="input-new-complexName"
                      className="form-control"
                      placeholder={t("model::fields::complexName")}
                      value={form.complexName}
                      onChange={updateField}
                      name="complexName"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-phone"
                    >
                      {t("model::fields::phone")}
                    </label>
                    <input
                      type="text"
                      id="input-new-phone"
                      className="form-control"
                      placeholder={t("model::fields::phone")}
                      value={form.phone}
                      onChange={updateField}
                      name="phone"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-email"
                    >
                      {t("model::fields::email")}
                    </label>
                    <input
                      type="email"
                      id="input-new-email"
                      className="form-control"
                      placeholder={t("model::fields::email")}
                      value={form.email}
                      onChange={updateField}
                      name="email"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-new-observation"
                    >
                      {t("model::fields::observation")}
                    </label>
                    <input
                      type="text"
                      id="input-new-observation"
                      className="form-control"
                      placeholder={t("model::fields::observation")}
                      value={form.observation}
                      onChange={updateField}
                      name="observation"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {t("actions::Close")}
                </button>
                <button type="submit" className="btn btn-primary">
                  {t("actions::Submit")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
