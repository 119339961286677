import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from 'react-s-alert';
import AuthService from '../AuthService';
import FetchService from '../FetchService';
import apiRoutes from '../apiRoutes';
import RolesGuard from '../RolesGuard';

export default function HeaderInfoWidgets(props) {
  const { t, i18n } = useTranslation();
  const [issuesCount, setIssuesCount] = useState([]);
  const [projectsCount, setProjectsCount] = useState([]);

  //  const url = window.CONFIG.SERVER_BASE_URL + apiRoutes.ASSET.LIST_COUNT;

  // useEffect(() => {
  //   FetchService.simpleFetch(apiRoutes.ISSUE.LIST_COUNT, setIssuesCount);
  // }, []);
  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.PROJECT.LIST_COUNT, setProjectsCount);
  }, []);
  useEffect(() => {
    FetchService.simpleFetch(apiRoutes.ISSUE.BYASSISTANT+"/"+props.idUser, setIssuesCount);
  }, []);

  return (
    <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
      <div className="container-fluid">
        <div className="header-body">
          {/* <!-- Card stats --> */}
          <div className="row">
            {/* <div className="col-xl-3 col-lg-6">
              <InfoWidget
                title={t('infoWidgets::Issues')}
                data={issuesCount.count}
                iconClass="fas fa-tasks"
                bgColorClass="bg-gradient-info"
                buttonText={t('infoWidgets::See all issues')}
                buttonLink="/issues"
              />
            </div> */}
            {RolesGuard.hasAnyRole(['ADMIN', 'INTERNAL', 'EXTERNAL'])?
            <div className="col-xl-3 col-lg-6">
              <InfoWidget
                title={t('infoWidgets::Projects')}
                data={projectsCount.count}
                iconClass="fas fa-briefcase"
                bgColorClass="bg-gradient-purple"
                buttonText={t('infoWidgets::See all projects')}
                buttonLink="/projects"
              />
            </div>:""}
            {RolesGuard.hasRole('ASSISTANT')?
             <div className="col-xl-3 col-lg-6">
             <IssuesInfoWidget
               title={t('infoWidgets::Issues')}
               data={issuesCount.length}
               iconClass="fas fa-briefcase"
               bgColorClass="bg-gradient-purple"
               buttonText={t('infoWidgets::See all issues')}
               buttonLink="/issuesByAssistant"
             />
           </div>:''
            }
          </div>
        </div>
      </div>
    </div>
  );
}

function InfoWidget(props) {
  return (
    <div className="card card-stats mb-4 mb-xl-0">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h5 className="card-title text-uppercase text-muted mb-0">{props.title}</h5>
            <span className="h2 font-weight-bold mb-0">{props.data}</span>
          </div>
          <div className="col-auto">
            <div className={'icon icon-shape ' + props.bgColorClass + ' text-white rounded-circle shadow'}>
              <i className={props.iconClass}></i>
            </div>
          </div>
        </div>
        <div className="mb-0 text-center">
          <Link className="btn btn-outline-primary btn-sm mt-2" to={props.buttonLink}>
            {props.buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
}

function IssuesInfoWidget(props) {
  return (
    <div className="card card-stats mb-4 mb-xl-0">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h5 className="card-title text-uppercase text-muted mb-0">{props.title}</h5>
            <span className="h2 font-weight-bold mb-0">{props.data}</span>
          </div>
          <div className="col-auto">
            <div className={'icon icon-shape ' + props.bgColorClass + ' text-white rounded-circle shadow'}>
              <i className={props.iconClass}></i>
            </div>
          </div>
        </div>
        <div className="mb-0 text-center">
          <Link className="btn btn-outline-primary btn-sm mt-2" to={props.buttonLink}>
            {props.buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
}
